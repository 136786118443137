import { Box, useTheme } from '@material-ui/core';

function PageFooter() {
  const theme = useTheme();
  return (
    <Box
      height="68px"
      style={{ backgroundColor: theme.palette.primary.main }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    />
  );
}

export default PageFooter;
