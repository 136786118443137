import { Box, Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageLayout from '../PageLayout';
import ReducerRootState from '../../objects/reducerRootState';
import { resetfaceTecMatchIDScanResponse, setIdStatusError } from '../../services/FaceTecService';

export default function IDErrorPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const idStatusError = useSelector((state: ReducerRootState) => state.faceTec.idStatusError);
  return (
    <PageLayout hvCentered>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="justify">
        <Box mb={4}>
          {idStatusError === 'NOT_AUSTRALIAN_PHOTO_ID'
            && (
            <Typography variant="body1">
              ID type not supported. We only accept Australian driver licence or passport. Please try again.
            </Typography>
            )}
          {idStatusError === 'ID_INVALID'
            && (
            <Typography variant="body1">
              Your ID cannot be verified. We only accept Australian driver licence or passport.
              <br />
              If you are having problems verifying ID try reducing background light or switch to another device such as a mobile phone.
            </Typography>
            )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(setIdStatusError(''));
            dispatch(resetfaceTecMatchIDScanResponse());
            history.push(`liveness${history.location.search}`);
          }}
          style={{ color: 'white' }}
        >
          Try Again
        </Button>
      </Box>
    </PageLayout>
  );
}
