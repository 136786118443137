import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { beforeSend } from './utils/SentryUtils';

Sentry.init({
  dsn: 'https://febbc8bdc3f24483b9cbd202302cc1e7@o878235.ingest.sentry.io/5829835',
  integrations: [new Integrations.BrowserTracing({
    tracingOrigins: [
      process.env.REACT_APP_REST_BASE_API_URL ? new URL(process.env.REACT_APP_REST_BASE_API_URL).host : '',
      process.env.REACT_APP_AUTH_BASE_API_URL ? new URL(process.env.REACT_APP_AUTH_BASE_API_URL).host : '',
    ],
  })],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENVIRONMENT,
  beforeSend,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
