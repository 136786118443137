/* eslint-disable class-methods-use-this */
import * as Sentry from '@sentry/react';
import { fetchFaceTecEnrolmentEventTicket, fetchFaceTecMatchIDScanEventTicket, setFaceTecSessionCompletelyDone } from '.';
import {
  FaceTecFaceScanResultCallback,
  FaceTecIDScanProcessor,
  FaceTecIDScanResult,
  FaceTecIDScanResultCallback,
  FaceTecIDScanStatus,
  FaceTecSessionResult,
  FaceTecSessionStatus,
} from '../../facetec/core-sdk/FaceTecSDK.js/FaceTecPublicApi';
import { FaceTecSDK } from '../../facetec/core-sdk/FaceTecSDK.js/FaceTecSDK';
import FaceTecEnrolmentParameters from '../../objects/faceTecEnrolmentParameters';
import { StoreRegistry } from '../../stores';
import {
  logFacetecFacemapHTTPValidated,
  logFacetecFacemapResult,
  logFacetecIdScanHTTPValidated,
  logFacetecIdScanResult,
  logFacetecSessionFinished,
} from '../AnalyticsService';

/* eslint-disable @typescript-eslint/no-empty-function */
class LivenessCheckAndIDScanProcessor implements FaceTecIDScanProcessor {
  // eslint-disable-next-line no-useless-constructor
  faceTecCompletelyDoneCallback: () => void

  faceTecLivenessCheckDone: boolean

  faceTecIDScanDone: boolean

  databaseRefID: string

  faceScanResultCallback: null | FaceTecFaceScanResultCallback

  idCheckResultCallback: null | FaceTecIDScanResultCallback

  sessionResultStatus: null | FaceTecSessionStatus

  idScanResultStatus: null | FaceTecIDScanStatus

  constructor(faceTecCompletelyDoneCallback) {
    Sentry.addBreadcrumb({
      message: 'Facetec SDK processor initialized',
      category: 'facetec.session',
    });

    this.faceTecCompletelyDoneCallback = faceTecCompletelyDoneCallback;
    this.faceTecLivenessCheckDone = false;
    this.faceTecIDScanDone = false;
    this.databaseRefID = new Date().toISOString().replace(/:/g, '-').replace(/\./g, '-');
    this.faceScanResultCallback = null;
    this.idCheckResultCallback = null;
    this.sessionResultStatus = null;
    this.idScanResultStatus = null;
  }

  async processSessionResultWhileFaceTecSDKWaits(sessionResult: FaceTecSessionResult, faceScanResultCallback: FaceTecFaceScanResultCallback) {
    Sentry.addBreadcrumb({
      message: 'FaceTec scanned face',
      category: 'facetec.ui',
      data: {
        status: FaceTecSDK.getFriendlyDescriptionForFaceTecSessionStatus(sessionResult.status),
      },
    });

    this.sessionResultStatus = sessionResult.status;
    const { store } = StoreRegistry;
    store.dispatch(logFacetecFacemapResult(sessionResult.status));

    if (sessionResult.status !== FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully) {
      faceScanResultCallback.cancel();
    } else {
      const result: FaceTecEnrolmentParameters = {
        faceScan: sessionResult.faceScan ? sessionResult.faceScan : '',
        auditTrail: sessionResult.auditTrail[0],
      };

      this.faceScanResultCallback = faceScanResultCallback;

      store.dispatch(fetchFaceTecEnrolmentEventTicket(result));
    }
  }

  faceScanResultCallbackProceedToNextStep(scanResultBlob: string) {
    Sentry.addBreadcrumb({
      message: 'FaceTec received face scan result blob',
      category: 'facetec.http',
    });

    const { store } = StoreRegistry;
    store.dispatch(logFacetecFacemapHTTPValidated());

    if (this.faceScanResultCallback !== null) {
      this.faceScanResultCallback.proceedToNextStep(scanResultBlob);
      this.faceTecLivenessCheckDone = true;
    }
  }

  async processIDScanResultWhileFaceTecSDKWaits(idScanResult: FaceTecIDScanResult, idCheckResultCallback: FaceTecIDScanResultCallback) {
    let side: string;
    let idImage: string;

    if (idScanResult.backImages.length === 0) {
      side = 'front';
      [idImage] = idScanResult.frontImages;
    } else {
      side = 'back';
      [idImage] = idScanResult.backImages;
    }

    Sentry.addBreadcrumb({
      message: 'FaceTec scanned ID',
      category: 'facetec.ui',
      data: {
        status: FaceTecSDK.getFriendlyDescriptionForFaceTecIDScanStatus(idScanResult.status),
        side,
      },
    });

    this.idScanResultStatus = idScanResult.status;
    const { store } = StoreRegistry;
    store.dispatch(logFacetecIdScanResult(idScanResult.status, side, idScanResult.idScan !== null));

    if (idScanResult.status !== FaceTecSDK.FaceTecIDScanStatus.Success) {
      idCheckResultCallback.cancel();
    } else {
      this.idCheckResultCallback = idCheckResultCallback;

      const { faceMapId } = StoreRegistry.store.getState().faceTec;
      const { minIDMatchLevel } = StoreRegistry.store.getState().faceTec;
      store.dispatch(fetchFaceTecMatchIDScanEventTicket(
        idScanResult.idScan ? idScanResult.idScan : '',
        idImage,
        faceMapId,
        side,
        minIDMatchLevel,
      ));
    }
  }

  idCheckResultCallbackProceedToNextStep(scanResultBlob: string, forceEndFacetecSession: boolean) {
    Sentry.addBreadcrumb({
      message: 'FaceTec received ID check result blob',
      category: 'facetec.http',
      data: {
        forceEndFacetecSession,
      },
    });

    const { store } = StoreRegistry;
    store.dispatch(logFacetecIdScanHTTPValidated(forceEndFacetecSession));

    if (this.idCheckResultCallback !== null) {
      if (forceEndFacetecSession) {
        this.idCheckResultCallback.cancel();
      } else {
        this.idCheckResultCallback.proceedToNextStep(scanResultBlob);
      }
      this.faceTecIDScanDone = true;
    }
  }

  idCheckResultCallbackCancel() {
    Sentry.addBreadcrumb({
      message: 'FaceTec ID cancelled',
      category: 'facetec.ui',
    });
    if (this.idCheckResultCallback !== null) {
      this.idCheckResultCallback.cancel();
    }
  }

  onFaceTecSDKCompletelyDone() {
    Sentry.addBreadcrumb({
      message: 'FaceTec completely done',
      category: 'facetec.session',
    });

    const { store } = StoreRegistry;
    this.faceTecCompletelyDoneCallback();
    store.dispatch(logFacetecSessionFinished(FaceTecSDK.getStatus()));
    store.dispatch(setFaceTecSessionCompletelyDone());
  }
}

export default LivenessCheckAndIDScanProcessor;
