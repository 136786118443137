import * as Sentry from '@sentry/react';
import { StoreRegistry } from '../../stores';

type GeoLocationResponse = { status: 'ok' } | { status: 'error', error: unknown };

export const types = {
  START_GEO_LOCATION: 'START_GEO_LOCATION',

  FAIL_GEO_LOCATION: 'FAIL_GEO_LOCATION',
  RECEIVE_NOT_PROVIDED_LOCATION_RESPONSE: 'RECEIVE_NOT_PROVIDED_LOCATION_RESPONSE',

  SEND_SET_GEO_LOCATION: 'SEND_SET_GEO_LOCATION',
  RECEIVE_GEO_LOCATION_RESPONSE: 'RECEIVE_GEO_LOCATION_RESPONSE',
};

export function startGeoLocation() {
  return {
    type: types.START_GEO_LOCATION,
  };
}

export function receiveGeoLocationResponse(success: boolean) {
  return {
    type: types.RECEIVE_GEO_LOCATION_RESPONSE,
    success,
  };
}

export function sendFailGeoLocation() {
  return {
    type: types.FAIL_GEO_LOCATION,
  };
}

export function receiveNotProvidedLocationResponse(success: boolean) {
  return {
    type: types.RECEIVE_NOT_PROVIDED_LOCATION_RESPONSE,
    success,
  };
}

export function failGeoLocation() {
  const endpoint = `${process.env.REACT_APP_REST_BASE_API_URL}/command`;
  const { authToken } = StoreRegistry.store.getState().auth;

  return (dispatch) => {
    dispatch(sendFailGeoLocation());
    const handleJSONResponse = (json: GeoLocationResponse) => {
      if (json.status === 'ok') {
        dispatch(receiveNotProvidedLocationResponse(true));
      } else {
        dispatch(receiveNotProvidedLocationResponse(false));
        Sentry.captureException(json.error);
      }
    };
    return fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        command: 'blockGPS',
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json()
            .then(handleJSONResponse)
            .catch((err) => {
              dispatch(receiveNotProvidedLocationResponse(false));
              Sentry.captureException(err);
            });
        } else {
          dispatch(receiveNotProvidedLocationResponse(false));
          Sentry.captureMessage('Bad response');
        }
      })
      .catch((err) => {
        dispatch(receiveNotProvidedLocationResponse(false));
        Sentry.captureException(err);
      });
  };
}
export function sendSetGeoLocation() {
  return {
    type: types.SEND_SET_GEO_LOCATION,
  };
}

export function setGeoLocation(longitude: number, latitude: number) {
  const endpoint = `${process.env.REACT_APP_REST_BASE_API_URL}/command`;
  const { authToken } = StoreRegistry.store.getState().auth;

  return (dispatch) => {
    // This is bad code, but it's clean enough.
    dispatch(sendSetGeoLocation());

    const handleJSONResponse = (json: GeoLocationResponse) => {
      if (json.status === 'ok') {
        dispatch(receiveGeoLocationResponse(true));
      } else {
        dispatch(receiveGeoLocationResponse(false));
        // TODO: Maybe use a different capture type? This works fine for now, though.
        Sentry.captureException(json.error);
      }
    };

    return fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        command: 'setGPS',
        longitude,
        latitude,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json()
            .then(handleJSONResponse)
            .catch((err) => {
              dispatch(receiveGeoLocationResponse(false));
              Sentry.captureException(err);
            });
        } else {
          dispatch(receiveGeoLocationResponse(false));
          Sentry.captureMessage('Bad geolocation response - but continuing anyway');
        }
      })
      .catch((err) => {
        dispatch(receiveGeoLocationResponse(false));
        Sentry.captureException(err);
      });
  };
}
