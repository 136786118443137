import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import GridLoader from 'react-spinners/GridLoader';

type LoadingViewProps = {
  loadingMessages?: Array<string>
}

function LoadingView(props: LoadingViewProps) {
  const theme = useTheme();
  const { loadingMessages } = props;
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [messageInterval, setMessageInterval] = useState<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (loadingMessages) {
      const displayNextMessage = () => {
        if (currentMessageIndex < loadingMessages.length - 1) {
          setCurrentMessageIndex((currentIndex) => {
            if (currentIndex < loadingMessages.length - 1) {
              return currentIndex + 1;
            }
            return currentIndex;
          });
        }
      };
      setMessageInterval(setInterval(displayNextMessage, 1000));
    }
    return function cleanup() {
      if (messageInterval !== null) {
        clearInterval(messageInterval);
      }
    };
  }, []);
  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: 'calc(100vh - 156px)',
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <GridLoader color={theme.palette.primary.main} />
      {
        loadingMessages
          ? (
            <Box mt={2}>
              <Typography variant="body1">{loadingMessages[currentMessageIndex]}</Typography>
            </Box>
          )
          : <></>
      }
    </div>
  );
}

LoadingView.defaultProps = {
  loadingMessages: [],
};

export default LoadingView;
