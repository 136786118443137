import { nanoid } from 'nanoid';
import AnalyticsState from '../../objects/analyticsState';

const initialState: AnalyticsState = {
  instanceId: nanoid(),
};

// eslint-disable-next-line import/prefer-default-export
export function analyticsReducer(state = initialState, action) {
  switch (action) {
    default:
      return state;
  }
}
