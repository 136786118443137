import AuthState from '../../objects/authState';
import { types } from './actions';

const initialState: AuthState = {
  isLoadingAuthToken: false,
  authToken: '',
  isAuthTokenError: false,
  isBrowserWarning: false,
};

export default function authReducer(state: AuthState = initialState, action): AuthState {
  switch (action.type) {
    case types.REQUEST_AUTH_TOKEN:
      return {
        ...state, isLoadingAuthToken: true, authToken: '', isAuthTokenError: false, isBrowserWarning: false,
      };
    case types.RECEIVE_AUTH_TOKEN:
      return {
        ...state, isLoadingAuthToken: false, authToken: action.authToken, isAuthTokenError: false, isBrowserWarning: false,
      };
    case types.RECEIVE_AUTH_TOKEN_BROWSER_WARNING:
      return {
        ...state, isLoadingAuthToken: false, authToken: action.authToken, isAuthTokenError: false, isBrowserWarning: true,
      };
    case types.DISMISS_AUTH_TOKEN_BROWSER_WARNING:
      return {
        ...state, isBrowserWarning: false,
      };
    case types.RECEIVE_AUTH_TOKEN_ERROR:
      return {
        ...state, isLoadingAuthToken: false, authToken: '', isAuthTokenError: true, isBrowserWarning: false,
      };
    default:
      return state;
  }
}
