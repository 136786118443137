import {
  Typography, Box, Button,
} from '@material-ui/core';
import React, { ReactNode } from 'react';

function NotificationView(props: { onConfirm: () => void, buttonText?: string, children: ReactNode | undefined }) {
  const { onConfirm, buttonText, children } = props;

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="justify">
      <Box mb={4}>
        <Typography
          variant="body1"
        >
          {children}
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={() => onConfirm()}
        style={{ color: 'white' }}
      >
        {buttonText}
      </Button>
    </Box>
  );
}

NotificationView.defaultProps = {
  buttonText: 'Continue',
};

export default NotificationView;
