import { StylesProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Provider } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { store, StoreRegistry } from '../../stores';
import { setupEnvironment } from '../../services/EnvironmentService';
import { setupTheme } from '../../services/ThemeService';
import { loadConfig } from '../../services/ConfigService';
import Router from './Routes';

StoreRegistry.register(store);
setupEnvironment();
const { tenant } = StoreRegistry.store.getState().environment;
setupTheme(tenant);
loadConfig(tenant);

function App(): JSX.Element {
  return (
    <StylesProvider injectFirst>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Router />
        </MuiPickersUtilsProvider>
      </Provider>
    </StylesProvider>
  );
}

export default App;
