import {
  Box, useTheme,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import ReducerRootState from '../../objects/reducerRootState';

function PageHeader() {
  const theme = useTheme();
  const tenantConfig = useSelector((state: ReducerRootState) => state.config.config);
  return (
    <Box
      height="88px"
      style={{ backgroundColor: theme.palette.primary.main }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <img src={tenantConfig.tenantLogoWhite} height="64px" alt="tenant logo" />
    </Box>
  );
}

export default PageHeader;
