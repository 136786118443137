/* eslint-disable import/prefer-default-export */
import { TenantConfig } from '../../objects/tenantConfig';
import pepperMoneyLogo from '../../assets/pepper_money_logo.png';
import pepperMoneyLogoWhite from '../../assets/pepper_money_logo_white.png';

export const config: TenantConfig = {
  tenantName: 'Pepper Money',
  tenantLogo: pepperMoneyLogo,
  tenantLogoWhite: pepperMoneyLogoWhite,
  minimumIDMatchLevel: 5,
  maximumLivenessAttempt: 6,
  maximumIDMatchBackAttempt: 3,
  maximumIDMatchFrontAttempt: 6,
  maximumDVSAttempt: 2,
};
