export const theme = {
  palette: {
    primary: {
      main: '#DF0024',
    },
    secondary: {
      main: '#f5f6f5',
    },
    warning: {
      main: '#FFEC00',
    },
    success: {
      main: '#7FC743',
    },
    error: {
      main: '#FF0000',
    },
  },
};

export default theme;
