export const getRedirectUri = (urlParams: URLSearchParams): string => {
  const redirectUri = urlParams.get('redirectUri');
  return redirectUri || '';
};

export const getClientID = (urlParams: URLSearchParams): string => {
  const clientID = urlParams.get('clientID');
  return clientID || '';
};

export const getBrykSessionToken = (urlParams: URLSearchParams): string => {
  const brykSessionToken = urlParams.get('sessionToken');
  return brykSessionToken || '';
};
