import { sendAnalyticsRaw } from './util';

/**
 * Send a log of the OCR data being loaded
 */
export function logOCRDataLoaded(error?: string) {
  return sendAnalyticsRaw('ocrDataLoaded', {});
}

/**
 * Send a log of the OCR data failing to load
 *
 * @param error The error that occurred when trying to load OCR data
 */
export function logOCRDataLoadError(error: string) {
  return sendAnalyticsRaw('ocrDataLoadError', { error });
}

/**
 * Send a log of the DVS page being submitted
 *
 * @param validAndSending If the DVS data was valid and the data was actually sent
 */
export function logDVSPageSubmitted(validAndSending: boolean) {
  return sendAnalyticsRaw('dvsPageSubmitted', { validAndSending });
}

/**
 * Send a log of the DVS page being submitted
 *
 * @param error What went wrong
 */
export function logDVSPageError(error: string) {
  return sendAnalyticsRaw('dvsPageUserError', { error });
}
