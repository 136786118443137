import {
  createStore, applyMiddleware, combineReducers, compose,
} from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import * as Sentry from '@sentry/react';
import { environmentReducer } from '../services/EnvironmentService';
import { themeReducer } from '../services/ThemeService';
import { configReducer } from '../services/ConfigService';
import { faceTecReducer } from '../services/FaceTecService';
import { dataVerificationReducer } from '../services/DataVerificationService';
import { locationReducer } from '../services/LocationService';
import { authReducer } from '../services/AuthService';
import { websocketReducer } from '../services/WebSocketService';
import { ocrReducer } from '../services/OCRService';
import { analyticsReducer } from '../services/AnalyticsService';
import ReducerRootState from '../objects/reducerRootState';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

let finalCreateStore;
// SAFETY: Typecast is simply to make typescript happy. It doesn't actually need this, but sentry defined this as an
//         any, which makes typescript cry. The target type is incorrect, but it compiles and that's all we care about.
if (process.env.NODE_ENV === 'production') {
  finalCreateStore = compose(applyMiddleware(thunk), sentryReduxEnhancer as () => void)(createStore);
} else {
  finalCreateStore = compose(applyMiddleware(thunk, createLogger()), sentryReduxEnhancer as () => void)(createStore);
}

const reducer = combineReducers<ReducerRootState>({
  analytics: analyticsReducer,
  auth: authReducer,
  config: configReducer,
  dataVerification: dataVerificationReducer,
  environment: environmentReducer,
  faceTec: faceTecReducer,
  ocr: ocrReducer,
  location: locationReducer,
  websocket: websocketReducer,
  theme: themeReducer,
});

const store = finalCreateStore(reducer);
export default store;
