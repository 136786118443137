import DataVerificationState from '../../objects/dataVerificationState';
import { types } from './actions';

const initialState: DataVerificationState = {
  dataVerificationResult: null,
};

export default function dataVerificationReducer(state: DataVerificationState = initialState, action): DataVerificationState {
  switch (action.type) {
    case types.RECEIVE_DVS_RESULT:
      return {
        ...state, dataVerificationResult: action.dvsResult,
      };
    default:
      return state;
  }
}
