import * as Sentry from '@sentry/react';
import AuthResponse from '../../objects/authResponse';
import { extractSessionId } from './selectors';
import { logBrowserDisplayWarning } from '../AnalyticsService/actions/browserWarning';

export const types = {
  REQUEST_AUTH_TOKEN: 'REQUEST_AUTH_TOKEN',
  RECEIVE_AUTH_TOKEN: 'RECEIVE_AUTH_TOKEN',
  RECEIVE_AUTH_TOKEN_BROWSER_WARNING: 'RECEIVE_AUTH_TOKEN_BROWSER_WARNING',
  DISMISS_AUTH_TOKEN_BROWSER_WARNING: 'DISMISS_AUTH_TOKEN_BROWSER_WARNING',
  RECEIVE_AUTH_TOKEN_ERROR: 'RECEIVE_AUTH_TOKEN_ERROR',
};

function requestAuthToken() {
  return {
    type: types.REQUEST_AUTH_TOKEN,
  };
}

function receiveAuthToken(authToken: string) {
  return {
    type: types.RECEIVE_AUTH_TOKEN,
    authToken,
  };
}

function receiveAuthTokenBrowserWarning(authToken: string) {
  return {
    type: types.RECEIVE_AUTH_TOKEN_BROWSER_WARNING,
    authToken,
  };
}

export function dismissAuthTokenBrowserWarning() {
  return {
    type: types.DISMISS_AUTH_TOKEN_BROWSER_WARNING,
  };
}

function receiveAuthTokenError() {
  return {
    type: types.RECEIVE_AUTH_TOKEN_ERROR,
  };
}

export function fetchAuthToken(sessionToken: string) {
  const endpoint = `${process.env.REACT_APP_REST_BASE_API_URL}/authenticate`;
  return (dispatch) => {
    dispatch(requestAuthToken());
    return fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: sessionToken,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json()
            .then((json: AuthResponse) => {
              if (json.success) {
                Sentry.setTag('session_id', extractSessionId(json.jwt));
                dispatch(receiveAuthToken(json.jwt ?? ''));
              } else if (json.error === 'BROWSER_NOT_SUPPORTED') {
                dispatch(receiveAuthTokenBrowserWarning(json.jwt ?? ''));
                dispatch(logBrowserDisplayWarning());
              } else {
                dispatch(receiveAuthTokenError());
              }
            });
        } else {
          response.text()
            .then((body: string) => {
              Sentry.captureMessage('Bad token response', (scope) => scope
                .setTransactionName('Fetch session token from auth token')
                .setLevel('warning')
                .setContext('Request', {
                  token: sessionToken || 'NULL',
                })
                .setContext('Response', {
                  status: response.status,
                  body,
                }));
            });
          dispatch(receiveAuthTokenError());
        }
      })
      .catch((err) => {
        Sentry.captureException(err, (scope) => scope
          .setTransactionName('Fetch session token from auth token')
          .setContext('Request', {
            token: sessionToken,
          }));
        dispatch(receiveAuthTokenError());
      });
  };
}
