import FaceTecState from '../../objects/faceTecState';
import { types } from './actions';

const initialState: FaceTecState = {
  faceMapId: '',

  isLoadingFaceTecSessionToken: false,
  faceTecSessionToken: '',
  facetecSessionTokenEventTicket: '',
  isFacetecSessionTokenEventTicketError: false,

  isLoadingFaceTecEnrolmentEventTicket: false,
  faceTecEnrolmentSuccessful: false,
  faceTecEnrolmentEventTicket: '',
  isFaceTecEnrolmentEventTicketError: false,

  isLoadingFaceTecMatchIDScanEventTicket: false,
  faceTecMatchIDScanSuccessful: false,
  faceTecMatchIDScanResponse: null,
  isFaceTecMatchIDScanEventTicketError: false,

  faceTecSessionComplelyDone: false,

  livenessFailedAttempt: 0,
  idStatusError: '',
  minIDMatchLevel: 5,
};

export default function faceTecReducer(state: FaceTecState = initialState, action): FaceTecState {
  switch (action.type) {
    case types.REQUEST_FACETEC_SESSION_TOKEN:
      return {
        ...state,
        isLoadingFaceTecSessionToken: true,
        facetecSessionTokenEventTicket: '',
        isFacetecSessionTokenEventTicketError: false,
      };

    case types.RECEIVE_FACETEC_SESSION_TOKEN:
      return {
        ...state,
        isLoadingFaceTecSessionToken: false,
        faceTecSessionToken: action.faceTecSessionToken,
      };

    case types.RECEIVE_FACETEC_SESSION_TOKEN_TICKET:
      return {
        ...state,
        facetecSessionTokenEventTicket: action.eventTicket,
      };

    case types.RECEIVE_FACETEC_SESSION_TOKEN_ERROR:
      return {
        ...state,
        isLoadingFaceTecSessionToken: false,
        faceTecSessionToken: '',
        isFacetecSessionTokenEventTicketError: true,
      };

    case types.REQUEST_FACETEC_ENROLMENT_EVENT_TICKET:
      return {
        ...state, isLoadingFaceTecEnrolmentEventTicket: true, faceTecEnrolmentEventTicket: '', isFaceTecEnrolmentEventTicketError: false,
      };
    case types.RECEIVE_FACETEC_ENROLMENT:
      return {
        ...state, faceTecEnrolmentSuccessful: true, faceMapId: action.faceMapId,
      };
    case types.RECEIVE_FACETEC_ENROLMENT_EVENT_TICKET:
      return {
        ...state, isLoadingFaceTecEnrolmentEventTicket: false, faceTecEnrolmentEventTicket: action.eventTicket, isFaceTecEnrolmentEventTicketError: false,
      };
    case types.RECEIVE_FACETEC_ENROLMENT_EVENT_TICKET_ERROR:
      return {
        ...state, isLoadingFaceTecEnrolmentEventTicket: false, faceTecEnrolmentEventTicket: '', isFaceTecEnrolmentEventTicketError: true,
      };

    case types.REQUEST_FACETEC_MATCH_ID_SCAN_EVENT_TICKET:
      return {
        ...state,
        isLoadingFaceTecMatchIDScanEventTicket: true,
        faceTecMatchIDScanResponse: null,
        isFaceTecMatchIDScanEventTicketError: false,
      };
    case types.RECEIVE_FACETEC_MATCH_ID_SCAN:
      return {
        ...state, faceTecMatchIDScanSuccessful: true,
      };
    case types.RECEIVE_FACETEC_MATCH_ID_SCAN_EVENT_TICKET:
      return {
        ...state, isLoadingFaceTecMatchIDScanEventTicket: false, faceTecMatchIDScanResponse: action.idScanResponse, isFaceTecMatchIDScanEventTicketError: false,
      };
    case types.RECEIVE_FACETEC_MATCH_ID_SCAN_EVENT_TICKET_ERROR:
      return {
        ...state,
        isLoadingFaceTecMatchIDScanEventTicket: false,
        faceTecMatchIDScanResponse: null,
        isFaceTecMatchIDScanEventTicketError: true,
      };

    case types.SET_FACETEC_SESSION_COMPLETELY_DONE:
      return {
        ...state, faceTecSessionComplelyDone: true,
      };

    case types.RESET_FACETEC_SESSION_TO_START:
      return {
        ...state,
        ...initialState,
      };

    case types.INCREASE_LIVENESS_FAILED_ATTEMPT:
      return {
        ...state, livenessFailedAttempt: state.livenessFailedAttempt + 1,
      };
    case types.SET_MINIMUM_ID_MATCH_LEVEL:
      return {
        ...state, minIDMatchLevel: action.minIDMatchLevel,
      };
    case types.SET_ID_STATUS_ERROR:
      return {
        ...state, idStatusError: action.idStatusError,
      };

    case types.RESET_FACETEC_MATCH_ID_SCAN_RESPONSE:
      return {
        ...state, faceTecMatchIDScanResponse: null,
      };
    default:
      return state;
  }
}
