import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import ReducerRootState from '../../objects/reducerRootState';
import IDErrorPage from '../IDErrorPage';
import LandingPage from '../LandingPage';
import LivenessPage from '../LivenessPage';
import OCRPage from '../OCRPage';
import PageHeader from '../PageHeader';
import SessionLoader from '../SessionLoader';
import RouterLogger from './RouterLogger';

const Router: React.FC = () => {
  const theme = useSelector((state: ReducerRootState) => state.theme.theme);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HashRouter>
        <PageHeader />
        <SessionLoader>
          <RouterLogger>
            <Switch>
              <Route exact path="/liveness" component={LivenessPage} />
              <Route exact path="/edit" component={OCRPage} />
              <Route exact path="/error" component={IDErrorPage} />
              <Route exact path="*" component={LandingPage} />
            </Switch>
          </RouterLogger>
        </SessionLoader>
      </HashRouter>
    </ThemeProvider>
  );
};

export default Router;
