import React from 'react';
import {
  Box, Button, ButtonGroup, Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CopyInput from './CopyInput';

enum Platform {
  iOS,
  Android,
  Other
}

/**
 * Get the current platform of the device
 */
function getPlatform(): Platform {
  const iOSPlatforms = [
    'iPhone',
    'iPad',
    'Macintosh', // Desktop OSX
  ];

  if (window.navigator.userAgent.indexOf('(Linux; Android ') !== -1) {
    return Platform.Android;
  }

  if (iOSPlatforms.findIndex((platform) => window.navigator.userAgent.indexOf(`(${platform}; `) !== -1) !== -1) {
    return Platform.iOS;
  }

  return Platform.Other;
}

function IOSPrompt(props: { url: string }): JSX.Element {
  const { url } = props;

  return (
    <>
      <Typography>Please click the button below and paste the link in Safari.</Typography>
      <Box mt={1}>
        <CopyInput value={url} />
      </Box>
    </>
  );
}

function AndroidPrompt(props: { url: string }): JSX.Element {
  const { url } = props;
  // Remove http://, https://, etc
  const fixedURL = url.substring(window.location.protocol.length + 2);

  return (
    <>
      <Typography>Press one of the buttons below, or click the &quot;Copy Link&quot; button and open it in another browser if these do not work.</Typography>
      <Box mt={1}>
        <ButtonGroup orientation="vertical" color="primary" variant="outlined" fullWidth>
          <Button
            onClick={() => { window.location.href = `intent://${fixedURL}#Intent;scheme=https;package=com.android.chrome;end`; }}
          >
            Open in Google Chrome
          </Button>
          <Button
            onClick={() => { window.location.href = `intent://${fixedURL}#Intent;scheme=https;package=com.sec.android.app.sbrowser;end`; }}
          >
            Open in Samsung Browser
          </Button>
          <CopyInput value={url} fixedHackyButtonGroupBullshit />
        </ButtonGroup>
      </Box>
    </>
  );
}

function DefaultPrompt(props: { url: string }): JSX.Element {
  const { url } = props;

  return (
    <>
      <Typography>Please click the button below and open it in another browser, such as Safari, Microsoft Edge or Google Chrome.</Typography>
      <Box mt={1}>
        <CopyInput value={url} />
      </Box>
    </>
  );
}

export default function BrowserWarning(): JSX.Element {
  const platform = getPlatform();
  const url = window.location.href;
  const theme = useTheme();

  let response;

  switch (platform) {
    case Platform.iOS:
      response = <IOSPrompt url={url} />;
      break;
    case Platform.Android:
      response = <AndroidPrompt url={url} />;
      break;
    case Platform.Other:
    default:
      response = <DefaultPrompt url={url} />;
      break;
  }

  return (
    <>
      <Box>
        <Typography>Hi there,</Typography>
        <Typography>It looks like you reached the ID verification page from an unsupported browser, or from within another app.</Typography>
        <Typography>
          To ensure that the identity verification process works correctly&nbsp;
          <span style={{ fontWeight: theme.typography.fontWeightBold }}>we require that you use a different browser.</span>
        </Typography>
        {response}
      </Box>
    </>
  );
}
