import ModifyOcrResponse from '../../objects/modifyOcrResponse';

export const types = {
  RECEIVE_DVS_RESULT: 'RECEIVE_DVS_RESULT',
};

export function receiveDVSResult(dvsResult: ModifyOcrResponse) {
  return {
    type: types.RECEIVE_DVS_RESULT,
    dvsResult,
  };
}
