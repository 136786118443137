import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import PageLayout from '../PageLayout';
import { failGeoLocation, setGeoLocation, startGeoLocation } from '../../services/LocationService';
import NotificationView from '../NotificationView';

export default function LandingPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startGeoLocation());

    navigator.geolocation.getCurrentPosition((position) => {
      dispatch(setGeoLocation(position.coords.longitude, position.coords.latitude));
    }, (err) => {
      dispatch(failGeoLocation());
      Sentry.captureException(err);
    });
  }, []);

  return (
    <PageLayout hvCentered>
      <NotificationView onConfirm={() => history.push(`liveness${history.location.search}`)}>
        By clicking Continue, you confirm that you are authorised to provide the personal details presented and have obtained the
        consent of the individuals to which the personal details relate for this information to be passed to
        and checked with the document issuer, official record holder, a credit bureau and authorised third
        parties for the purpose of verifying your identity and address. You also confirm that if required,
        evidence of this consent can be produced.
      </NotificationView>
    </PageLayout>
  );
}
