import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingView from '../LoadingView';
import ReducerRootState from '../../objects/reducerRootState';
import { fetchAuthToken } from '../../services/AuthService';
import ErrorView from '../ErrorView';
import { initiateWebSocket } from '../../services/WebSocketService';
import PageLayout from '../PageLayout';
import BrowserWarning from './BrowserWarning';

/**
 * Renders a page wrapped in standard styling
 */
function PageWrap(props: {children: JSX.Element}): JSX.Element {
  const { children } = props;

  return (
    <PageLayout hvCentered>
      {children}
    </PageLayout>
  );
}

/**
 * Ensures a session is loaded and running prior to rendering children.
 */
export default function SessionLoader(props: { children: JSX.Element }): JSX.Element {
  const { children } = props;
  const { authToken, isAuthTokenError, isBrowserWarning } = useSelector((state: ReducerRootState) => state.auth);
  const brykSessionToken = useSelector((state: ReducerRootState) => state.environment.brykSessionToken);
  const websocket = useSelector((state: ReducerRootState) => state.websocket.websocket);
  const dispatch = useDispatch();

  // Actually perform auth
  useEffect(() => {
    dispatch(fetchAuthToken(brykSessionToken));
  }, [brykSessionToken]);

  // Post-auth init
  useEffect(() => {
    if (authToken && !websocket) {
      initiateWebSocket();
    }
  }, [authToken, websocket]);

  if (isAuthTokenError) {
    return (
      <PageWrap>
        <ErrorView message="Something went wrong with setting up your BrykID session." />
      </PageWrap>
    );
  }

  if (isBrowserWarning) {
    return (
      <PageWrap>
        <BrowserWarning />
      </PageWrap>
    );
  }

  if (authToken && websocket) {
    return children;
  }

  return (
    <PageWrap>
      <LoadingView loadingMessages={['Preparing your BrykID session']} />
    </PageWrap>
  );
}
