import { useMediaQuery, Typography, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';

function ErrorView(props: { message?: string }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { message } = props;

  return (
    <div style={{
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: isMobile ? 'calc(100vh - 64px)' : 'calc(100vh - 136px)',
      display: 'flex',
    }}
    >
      <Box ml={2}>
        <Typography
          variant="body1"
        >
          {`${message} Please try again.`}
        </Typography>
      </Box>
    </div>
  );
}

ErrorView.defaultProps = {
  message: 'Something went wrong.',
};

export default ErrorView;
