import * as Sentry from '@sentry/react';
import ReducerRootState from '../../objects/reducerRootState';

export function extractSessionId(authToken: string) {
  const tokenParts = authToken.split('.');

  if (tokenParts.length !== 3) {
    return null;
  }

  let sessionId: string;

  try {
    const parsedToken = JSON.parse(atob(tokenParts[1]));
    if (!parsedToken.sessionId) {
      Sentry.captureMessage('No clientId property within valid JWT during selectSessionId selector!');
    }

    sessionId = parsedToken.sessionId;
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }

  return sessionId;
}

export function selectSessionId(state: ReducerRootState): string | null {
  // Globalized selectors make for easier use outside of this function, but localised selectors focus the intent.
  // So, let's try to do both... sorta.
  const authState = state.auth;

  return extractSessionId(authState.authToken);
}

export function selectFullRedirectUri(state: ReducerRootState): string {
  const sessionId = selectSessionId(state);

  const originalRedirectUri = state.environment.redirectUri;
  const urlParts = originalRedirectUri.split('?', 2);
  const redirectBase = urlParts[0];
  // undefined is unnecessary, but let's be explicit here. Null will cause bad behavior.
  const urlQuery = new URLSearchParams(urlParts[1] ?? undefined);
  // -1 is an error here, but it's at least going to make the typechecker happy.
  // This should never happen, but if you, dear traveller, find this to be an error,
  // you should fill the sessionId earlier by requesting a BrykID session key earlier on. Your error is not here.
  urlQuery.set('sessionId', sessionId ?? '-1');

  return `${redirectBase}?${urlQuery}`;
}
