import * as Sentry from '@sentry/react';
import ReducerRootState from '../../../objects/reducerRootState';
import { StoreRegistry } from '../../../stores';

/**
 * Send analytics data to the backend within the context of our session.
 *
 * NOTE: We ignore the result as this is an entirely failable request. If this fails, we let it pass.
 * @param type The analytics event to send
 * @param data The data associated with the event
 * @param callback An optional callback to call after the event has been flushed
 */
// Implementor's note: we don't dispatch anything as we don't care.
// If we could, we'd use sendBeacon, which would allow us to emit the event even if the page closes.
// Unfortunately though, auth doesn't work nicely with that based on how we currently do it.
// The ideal API: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
// eslint-disable-next-line import/prefer-default-export
export function sendAnalyticsRaw(type: string, data: Record<string, string | number | boolean>, callback?: () => void) {
  const endpoint = `${process.env.REACT_APP_REST_BASE_API_URL}/command`;

  const state: ReducerRootState = StoreRegistry.store.getState();
  const { authToken } = state.auth;
  const { instanceId } = state.analytics;

  return (dispatch) => {
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        command: 'analytics',
        instanceId,
        type,
        data,
      }),
    })
      .catch((err) => Sentry.captureException(err))
      .finally(() => callback && callback());
  };
}
