import ConfigState from '../../objects/configState';
import { types } from './actions';

const initialState: ConfigState = {
  config: {
    tenantName: '',
    tenantLogo: '',
    tenantLogoWhite: '',
    minimumIDMatchLevel: 0,
    maximumLivenessAttempt: -1,
    maximumIDMatchFrontAttempt: -1,
    maximumIDMatchBackAttempt: -1,
    maximumDVSAttempt: -1,
  },
};

export default function configReducer(state: ConfigState = initialState, action) {
  switch (action.type) {
    case types.SET_CONFIG:
      return { ...state, config: action.config };
    default:
      return state;
  }
}
