import { StoreRegistry } from '../../stores';
import { getBrykSessionToken, getClientID, getRedirectUri } from '../../utils/ClientUtils';
import { getTenantForDevelopment, getTenantForProduction } from '../../utils/TenantUtils';

export const types = {
  INITIAL_SETUP: 'INITIAL_SETUP',
  SET_NODE_ENV: 'SET_NODE_ENV',
  SET_ENVIRONMENT: 'SET_ENVIRONMENT',
  SET_TENANT: 'SET_TENANT',
  SET_THEME: 'SET_THEME',
  COMPLETE_INITIAL_SETUP: 'COMPLETE_INITIAL_SETUP',
};

export function initialSetup(nodeEnv: string, environment: string, tenant: string, redirectUri: string, clientID: string, brykSessionToken: string) {
  return {
    type: types.INITIAL_SETUP,
    nodeEnv,
    environment,
    tenant,
    redirectUri,
    clientID,
    brykSessionToken,
  };
}

export function setNodeEnv(nodeEnv: string) {
  return {
    type: types.SET_NODE_ENV,
    nodeEnv,
  };
}

export function setTenant(tenant: string) {
  return {
    type: types.SET_TENANT,
    tenant,
  };
}

export function setEnvironment(environment: string) {
  return {
    type: types.SET_ENVIRONMENT,
    environment,
  };
}

export function setTheme(theme: string[]) {
  return {
    type: types.SET_THEME,
    theme,
  };
}

export function completeInitialSetup() {
  return {
    type: types.COMPLETE_INITIAL_SETUP,
  };
}

export async function setupEnvironment() {
  const { store } = StoreRegistry;
  let tenant = '';
  const urlParams = new URLSearchParams(window.location.hash.split('?', 2)[1] ?? '');
  const redirectUri = getRedirectUri(urlParams);
  const clientID = getClientID(urlParams);
  const brykSessionToken = getBrykSessionToken(urlParams);

  if (process.env.NODE_ENV === 'production') {
    tenant = getTenantForProduction();
  } else {
    tenant = getTenantForDevelopment(urlParams);
  }

  const environment = process.env.REACT_APP_ENVIRONMENT || '';
  store.dispatch(initialSetup(process.env.NODE_ENV ?? 'development', environment, tenant, redirectUri, clientID, brykSessionToken));
  store.dispatch(completeInitialSetup());
}
