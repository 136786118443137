import { FaceTecSDK } from '../../../facetec/core-sdk/FaceTecSDK.js/FaceTecSDK';
import { sendAnalyticsRaw } from './util';

/**
 * Get the name of the FaceTec SDK status
 *
 * @param rawStatus The numeric status
 */
function extractRawFacetecSDKStatus(rawStatus: number) {
  const { FaceTecSDKStatus } = FaceTecSDK;
  switch (rawStatus) {
    case FaceTecSDKStatus.NeverInitialized:
      return 'NeverInitialized';
    case FaceTecSDKStatus.Initialized:
      return 'Initialized';
    case FaceTecSDKStatus.NetworkIssues:
      return 'NetworkIssues';
    case FaceTecSDKStatus.InvalidDeviceKeyIdentifier:
      return 'InvalidDeviceKeyIdentifier';
    case FaceTecSDKStatus.VersionDeprecated:
      return 'VersionDeprecated';
    case FaceTecSDKStatus.DeviceNotSupported:
      return 'DeviceNotSupported';
    case FaceTecSDKStatus.DeviceInLandscapeMode:
      return 'DeviceInLandscapeMode';
    case FaceTecSDKStatus.DeviceLockedOut:
      return 'DeviceLockedOut';
    case FaceTecSDKStatus.KeyExpiredOrInvalid:
      return 'KeyExpiredOrInvalid';
    case FaceTecSDKStatus.IFrameNotAllowedWithoutPermission:
      return 'IFrameNotAllowedWithoutPermission';
    case FaceTecSDKStatus.StillLoadingResources:
      return 'StillLoadingResources';
    case FaceTecSDKStatus.ResourcesCouldNotBeLoadedOnLastInit:
      return 'ResourcesCouldNotBeLoadedOnLastInit';
    default:
      return 'UNKNOWN';
  }
}

/**
 * Get the name of the FaceTec Facemap status
 *
 * @param rawStatus The numeric status
 */
function extractRawFacetecFacemapStatus(rawStatus: number) {
  const { FaceTecSessionStatus } = FaceTecSDK;

  switch (rawStatus) {
    case FaceTecSessionStatus.SessionCompletedSuccessfully:
      return 'SessionCompletedSuccessfully';
    case FaceTecSessionStatus.MissingGuidanceImages:
      return 'MissingGuidanceImages';
    case FaceTecSessionStatus.Timeout:
      return 'Timeout';
    case FaceTecSessionStatus.ContextSwitch:
      return 'ContextSwitch';
    case FaceTecSessionStatus.ProgrammaticallyCancelled:
      return 'ProgrammaticallyCancelled';
    case FaceTecSessionStatus.OrientationChangeDuringSession:
      return 'OrientationChangeDuringSession';
    case FaceTecSessionStatus.LandscapeModeNotAllowed:
      return 'LandscapeModeNotAllowed';
    case FaceTecSessionStatus.UserCancelled:
      return 'UserCancelled';
    case FaceTecSessionStatus.UserCancelledFromNewUserGuidance:
      return 'UserCancelledFromNewUserGuidance';
    case FaceTecSessionStatus.UserCancelledFromRetryGuidance:
      return 'UserCancelledFromRetryGuidance';
    case FaceTecSessionStatus.UserCancelledWhenAttemptingToGetCameraPermissions:
      return 'UserCancelledWhenAttemptingToGetCameraPermissions';
    case FaceTecSessionStatus.LockedOut:
      return 'LockedOut';
    case FaceTecSessionStatus.CameraNotEnabled:
      return 'CameraNotEnabled';
    case FaceTecSessionStatus.NonProductionModeDeviceKeyIdentifierInvalid:
      return 'NonProductionModeDeviceKeyIdentifierInvalid';
    case FaceTecSessionStatus.DocumentNotReady:
      return 'DocumentNotReady';
    case FaceTecSessionStatus.SessionInProgress:
      return 'SessionInProgress';
    case FaceTecSessionStatus.CameraNotRunning:
      return 'CameraNotRunning';
    case FaceTecSessionStatus.InitializationNotCompleted:
      return 'InitializationNotCompleted';
    case FaceTecSessionStatus.UnknownInternalError:
      return 'UnknownInternalError';
    case FaceTecSessionStatus.UserCancelledViaClickableReadyScreenSubtext:
      return 'UserCancelledViaClickableReadyScreenSubtext';
    case FaceTecSessionStatus.NotAllowedUseIframeConstructor:
      return 'NotAllowedUseIframeConstructor';
    case FaceTecSessionStatus.NotAllowedUseNonIframeConstructor:
      return 'NotAllowedUseNonIframeConstructor';
    case FaceTecSessionStatus.IFrameNotAllowedWithoutPermission:
      return 'IFrameNotAllowedWithoutPermission';
    case FaceTecSessionStatus.StillLoadingResources:
      return 'StillLoadingResources';
    case FaceTecSessionStatus.ResourcesCouldNotBeLoadedOnLastInit:
      return 'ResourcesCouldNotBeLoadedOnLastInit';
    case FaceTecSessionStatus.UserCancelledFullScreenMode:
      return 'UserCancelledFullScreenMode';
    default:
      return 'UNKNOWN';
  }
}

/**
 * Get the name of the FaceTec ID scan status
 *
 * @param rawStatus The numeric status
 */
function extractRawFacetecIDScanStatus(rawStatus: number) {
  const { FaceTecIDScanStatus } = FaceTecSDK;

  switch (rawStatus) {
    case FaceTecIDScanStatus.Success:
      return 'Success';
    case FaceTecIDScanStatus.Unsuccess:
      return 'Unsuccess';
    case FaceTecIDScanStatus.UserCancelled:
      return 'UserCancelled';
    case FaceTecIDScanStatus.TimedOut:
      return 'TimedOut';
    case FaceTecIDScanStatus.ContextSwitch:
      return 'ContextSwitch';
    case FaceTecIDScanStatus.CameraError:
      return 'CameraError';
    case FaceTecIDScanStatus.CameraNotEnabled:
      return 'CameraNotEnabled';
    case FaceTecIDScanStatus.Skipped:
      return 'Skipped';
    default:
      return 'UNKNOWN';
  }
}

/**
 * Send a log of the facetec init status to the analytics system
 *
 * @param rawStatus The facetec status
 */
export function logFacetecSDKInit(rawStatus: number) {
  const status = extractRawFacetecSDKStatus(rawStatus);

  return sendAnalyticsRaw('facetecSDKInitStatus', {
    rawStatus,
    status,
  });
}

/**
 * Send a log of the facetec session finishing
 *
 * @param rawStatus The facetec session status
 */
export function logFacetecFacemapResult(rawStatus: number) {
  const status = extractRawFacetecFacemapStatus(rawStatus);

  return sendAnalyticsRaw('facetecSDKFacemapScanned', {
    rawStatus,
    status,
  });
}

/**
 * Send a log of the facetec session finishing
 */
export function logFacetecFacemapHTTPValidated() {
  return sendAnalyticsRaw('facetecSDKFacemapValidated', {});
}

/**
 * Send a log of the facetec ID scan finishing
 *
 * @param rawStatus The facetec session status
 * @param side The side of the ID we tried
 * @param idScanExists An ID scan exists for this attempt
 */
export function logFacetecIdScanResult(rawStatus: number, side: string, idScanExists: boolean) {
  const status = extractRawFacetecIDScanStatus(rawStatus);

  return sendAnalyticsRaw('facetecSDKIdScanned', {
    rawStatus,
    status,
    side,
    idScanExists,
  });
}

/**
 * Send a log of the facetec session finishing
 */
export function logFacetecIdScanHTTPValidated(forceEndFacetecSession: boolean) {
  return sendAnalyticsRaw('facetecSDKIdScanValidated', {
    forceEndFacetecSession
  });
}

/**
 * Send a log of the facetec session finishing
 */
export function logFacetecSessionFinished(rawStatus: number) {
  const status = extractRawFacetecSDKStatus(rawStatus);

  return sendAnalyticsRaw('facetecSDKFinishedStatus', {
    rawStatus,
    status,
  });
}
