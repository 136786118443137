type WebSocketError = {
  status: 'error',
  error: string
}

export function isWebSocketError(val: unknown): val is WebSocketError {
  if (typeof val !== 'object' || val === null) {
    return false;
  }

  // eslint-disable-next-line dot-notation
  return val['status'] === 'error' && typeof val['status'] === 'string';
}

export default WebSocketError;
