/* eslint-disable prefer-destructuring */
import {
  Box, Theme, Typography, useTheme,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { FaceTecSDK } from '../../facetec/core-sdk/FaceTecSDK.js/FaceTecSDK';
import { deviceKeyIdentifier, productionToken, publicFaceMapEncryptionKey } from '../../constants/facetecConfig';
import PageLayout from '../PageLayout';
import {
  increaseLivenessFailedAttempt,
  LivenessCheckAndIDScanProcessor,
  receiveFaceTecSessionToken,
  setIdStatusError,
} from '../../services/FaceTecService';
import LoadingView from '../LoadingView';
import ReducerRootState from '../../objects/reducerRootState';
import { TenantConfig } from '../../objects/tenantConfig';
import type { FaceTecCustomization } from '../../facetec/core-sdk/FaceTecSDK.js/FaceTecCustomization';
import { selectFullRedirectUri } from '../../services/AuthService';
import CheckLivenessResponse from '../../objects/checkLivenessResponse';
import {
  fetchFaceTecSessionToken,
  receiveFaceTecSessionTokenError,
  resetFaceTecSessionToStart,
  setMinimumIDMatchLevel,
} from '../../services/FaceTecService/actions';
import FaceTecTokenResponse from '../../objects/faceTecTokenResponse';
import ErrorView from '../ErrorView';
import { logFacetecSDKInit } from '../../services/AnalyticsService';

enum FaceTecReadyState {
  Loading,
  Ready,
  Fail
}
const FACETEC_LOCALIZATION_STRINGS = {
  FaceTec_result_idscan_retry_id_type_not_suppported_message:
   `<p text-align: left>We only support:</p>
    <ul style="display: table; margin: 0 auto; text-align: left;">
    <li>Australian state or territory drivers licences (physical ID cards only)</li><li>Australian passports</li></ul>`,
  FaceTec_idscan_type_selection_header:
   `<p style="text-align: left; text-align: left;margin: 0 auto;">
    Prepare to scan your ID<p  style="text-align: left; margin: 8px 0 auto;font-size:1.4rem;">
    We support these IDs:</p></p><ul style="display: table; margin: 0 auto; text-align: left;font-size:17px">
    <li> Australian state or territory drivers licences</br>(physical ID cards only)</li><li>Australian passports</li></ul>`,
  FaceTec_result_idscan_retry_ocr_results_not_good_enough_message:
   `<p style="text-align: left; margin: 0 auto; font-size:1rem;">
    Low quality ID scan - please try again</p><ul style="display: table; margin: 0 auto; text-align: left;">
   <li> use ambient light</li><li>avoid glare, reflections</li><li>no photos of screens</li></ul>`,
};

function getFaceTecCustomization(theme: Theme, tenantConfig: TenantConfig): FaceTecCustomization {
  const customization = new FaceTecSDK.FaceTecCustomization();

  const buttonDisabledColour = tenantConfig.tenantName === 'Bryk' || tenantConfig.tenantName === 'BRYK'
    ? '#555559'
    : theme.palette.primary.dark;

  customization.cancelButtonCustomization.location = FaceTecSDK.FaceTecCancelButtonLocation.Disabled;

  // FaceTec Frame
  customization.frameCustomization.borderColor = theme.palette.primary.main;
  customization.frameCustomization.backgroundColor = '#fff';

  // Overlay
  customization.overlayCustomization.backgroundColor = '#ffffffaf';
  customization.overlayCustomization.brandingImage = tenantConfig.tenantLogo;
  // Feedback Bar
  customization.feedbackCustomization.backgroundColor = theme.palette.primary.main;
  customization.feedbackCustomization.textColor = '#fff';

  // FaceTec Oval
  customization.ovalCustomization.strokeColor = theme.palette.primary.main;
  customization.ovalCustomization.progressColor1 = theme.palette.primary.main;
  customization.ovalCustomization.progressColor2 = theme.palette.primary.main;

  // BackgroundColor
  customization.guidanceCustomization.buttonBackgroundNormalColor = theme.palette.primary.main;
  customization.guidanceCustomization.buttonBackgroundHighlightColor = theme.palette.primary.main;
  customization.guidanceCustomization.buttonBackgroundDisabledColor = buttonDisabledColour;
  customization.guidanceCustomization.buttonTextDisabledColor = '#fff';
  customization.guidanceCustomization.readyScreenHeaderTextColor = theme.palette.primary.main;
  customization.guidanceCustomization.retryScreenHeaderTextColor = theme.palette.primary.main;
  customization.guidanceCustomization.readyScreenSubtextTextColor = theme.palette.primary.main;
  customization.guidanceCustomization.retryScreenSubtextTextColor = theme.palette.primary.main;
  customization.guidanceCustomization.foregroundColor = theme.palette.primary.main;

  // Initial load
  customization.initialLoadingAnimationCustomization.foregroundColor = theme.palette.primary.main;
  customization.initialLoadingAnimationCustomization.messageTextColor = theme.palette.primary.main;

  // Result screen
  customization.resultScreenCustomization.foregroundColor = theme.palette.primary.main;
  customization.resultScreenCustomization.activityIndicatorColor = theme.palette.primary.main;
  customization.resultScreenCustomization.uploadProgressFillColor = theme.palette.primary.main;
  customization.resultScreenCustomization.resultAnimationForegroundColor = '#fff';
  // customization.resultScreenCustomization.resultAnimationUnsuccessBackgroundImage = failureIcon;
  customization.resultScreenCustomization.resultAnimationBackgroundColor = theme.palette.primary.main;

  // ID Scan
  customization.idScanCustomization.selectionScreenBrandingImage = tenantConfig.tenantLogo;
  customization.idScanCustomization.showSelectionScreenBrandingImage = true;
  customization.idScanCustomization.selectionScreenForegroundColor = theme.palette.primary.main;
  customization.idScanCustomization.buttonBackgroundNormalColor = theme.palette.primary.main;
  customization.idScanCustomization.buttonBackgroundHighlightColor = theme.palette.primary.main;
  customization.idScanCustomization.buttonBackgroundDisabledColor = buttonDisabledColour;
  customization.idScanCustomization.buttonTextDisabledColor = '#fff';
  customization.idScanCustomization.captureFrameStrokeColor = theme.palette.primary.main;
  customization.idScanCustomization.captureScreenTextBackgroundColor = theme.palette.primary.main;
  customization.idScanCustomization.disableAdditionalReviewScreen = true;

  return customization;
}

export default function LivenessPage() {
  const [faceTecReadyState, setFaceTecReadyState] = useState(FaceTecReadyState.Loading);
  const [faceTecProcessor, setFaceTecProcessor] = useState<LivenessCheckAndIDScanProcessor | null>(null);
  const dispatch = useDispatch();
  const theme = useTheme();
  const tenantConfig = useSelector((state: ReducerRootState) => state.config.config);
  const faceTecEnrolmentSuccessful = useSelector((state: ReducerRootState) => state.faceTec.faceTecEnrolmentSuccessful);
  const faceTecMatchIDScanSuccessful = useSelector((state: ReducerRootState) => state.faceTec.faceTecMatchIDScanSuccessful);
  const faceTecSessionCompletelyDone = useSelector((state: ReducerRootState) => state.faceTec.faceTecSessionComplelyDone);
  const {
    facetecSessionTokenEventTicket,
    faceTecSessionToken,
    isLoadingFaceTecSessionToken,
    isFacetecSessionTokenEventTicketError,
  } = useSelector((state: ReducerRootState) => state.faceTec);
  const faceTecEnrolmentEventTicket = useSelector((state: ReducerRootState) => state.faceTec.faceTecEnrolmentEventTicket);
  const faceTecMatchIDScanResponse = useSelector((state: ReducerRootState) => state.faceTec.faceTecMatchIDScanResponse);
  const sseEvents = useSelector((state: ReducerRootState) => state.websocket.events);
  const livenessFailedAttempt = useSelector((state: ReducerRootState) => state.faceTec.livenessFailedAttempt);
  const redirectUri = useSelector(selectFullRedirectUri);
  const history = useHistory();

  const [preInit, setPreInit] = useState(true);

  /**
   * Handle post-session redirects/other actions
   */
  useEffect(() => {
    if (preInit) {
      dispatch(resetFaceTecSessionToStart());
      dispatch(fetchFaceTecSessionToken());
      setPreInit(false);
      return;
    }

    if (!faceTecSessionCompletelyDone) {
      return;
    }

    if (faceTecEnrolmentSuccessful && faceTecMatchIDScanSuccessful) {
      history.push(`edit${history.location.search}`);
    } else {
      Sentry.captureMessage(
        'FaceTec session failed',
        (scope) => scope
          .setTransactionName('FaceTec SDK Session')
          .setTag('faceTecEnrolmentSuccessful', faceTecEnrolmentSuccessful)
          .setTag('faceTecMatchIDScanSuccessful', faceTecMatchIDScanSuccessful)
          .setContext('FaceTec Session Status', {
            sessionStatus: faceTecProcessor?.sessionResultStatus
              ? FaceTecSDK.getFriendlyDescriptionForFaceTecSessionStatus(faceTecProcessor?.sessionResultStatus)
              : null,
            idScanStatus: faceTecProcessor?.idScanResultStatus
              ? FaceTecSDK.getFriendlyDescriptionForFaceTecIDScanStatus(faceTecProcessor?.idScanResultStatus)
              : null,
          }),
      );
      Sentry.flush().then(() => {
        window.location.href = redirectUri;
      });
    }
  }, [preInit, faceTecSessionCompletelyDone]);

  /**
   * Initiate FaceTec & Auth Token
   */
  useEffect(() => {
    // If FaceTec hasn't been initialised
    if (FaceTecSDK.getStatus() === 0) {
      const onInitializationComplete = (initializedSucessfully: boolean) => {
        dispatch(logFacetecSDKInit(FaceTecSDK.getStatus()));
        if (initializedSucessfully) {
          FaceTecSDK.configureLocalization(FACETEC_LOCALIZATION_STRINGS);
          setFaceTecReadyState(FaceTecReadyState.Ready);
        } else {
          setFaceTecReadyState(FaceTecReadyState.Fail);
        }
      };

      const customization = getFaceTecCustomization(theme, tenantConfig);

      FaceTecSDK.setCustomization(customization);
      FaceTecSDK.setResourceDirectory('/FaceTecAuthentication.js/resources');
      FaceTecSDK.setImagesDirectory('/FaceTecAuthentication.js/FaceTec_images');
      if (productionToken) {
        FaceTecSDK.initializeInProductionMode(productionToken, deviceKeyIdentifier, publicFaceMapEncryptionKey, onInitializationComplete);
      } else {
        FaceTecSDK.initializeInDevelopmentMode(deviceKeyIdentifier, publicFaceMapEncryptionKey, onInitializationComplete);
      }
      // just for testing and will be removed in next push
      console.log('Note : ', FaceTecSDK.version());
    } else {
      setFaceTecReadyState(FaceTecReadyState.Ready);
    }
  }, []);

  /**
   * Complete or retry liveness check after we get response from both REST & SSE server
   */
  useEffect(() => {
    const latestSSEEvent = sseEvents[sseEvents.length - 1];
    if (faceTecEnrolmentEventTicket.length > 0 && latestSSEEvent !== undefined && faceTecEnrolmentEventTicket === latestSSEEvent.eventTicket) {
      const checkLivenessResponse = (latestSSEEvent.result as CheckLivenessResponse);
      if (!checkLivenessResponse.success) {
        dispatch(increaseLivenessFailedAttempt());
      }
      faceTecProcessor?.faceScanResultCallbackProceedToNextStep(checkLivenessResponse.resultBlob);
      // if ((latestSSEEvent.result as CheckLivenessResponse).success) {
      //   faceTecProcessor?.faceScanResultCallbackProceedToNextStep('');
      // } else {
      //   faceTecProcessor?.faceScanResultCallbackRetry();
      // }
    }
  }, [faceTecEnrolmentEventTicket, sseEvents]);

  /**
   * Fetch facetec initial session token
   */
  useEffect(() => {
    const latestSSEEvent = sseEvents[sseEvents.length - 1];
    if (facetecSessionTokenEventTicket.length > 0 && latestSSEEvent !== undefined && facetecSessionTokenEventTicket === latestSSEEvent.eventTicket) {
      const tokenResponse = (latestSSEEvent.result as FaceTecTokenResponse);
      if (tokenResponse.status === 'ok') {
        dispatch(receiveFaceTecSessionToken(tokenResponse.token));
      } else {
        dispatch(receiveFaceTecSessionTokenError());
      }
    }
  }, [facetecSessionTokenEventTicket, sseEvents]);

  useEffect(() => {
    const exceededLivenessAttempts = livenessFailedAttempt === tenantConfig.maximumLivenessAttempt;
    let exceededIdMatchSideAttemts = false;

    const idScanPassed = faceTecMatchIDScanResponse?.passed;
    if (idScanPassed === false) {
      const sideAttempts = faceTecMatchIDScanResponse?.totalSideAttempts ?? 0;
      const idMatchSide = faceTecMatchIDScanResponse?.side;

      switch (idMatchSide) {
        case 'front':
          exceededIdMatchSideAttemts = sideAttempts >= tenantConfig.maximumIDMatchFrontAttempt;
          break;
        case 'back':
          exceededIdMatchSideAttemts = sideAttempts >= tenantConfig.maximumIDMatchBackAttempt;
          break;
        default:
      }
    }

    if (exceededLivenessAttempts || exceededIdMatchSideAttemts) {
      Sentry.captureMessage(
        'Exceeded max attempts during liveness',
        (scope) => scope
          .setTransactionName('FaceTec SDK Session')
          .setContext('attempts', {
            livenessFailedAttempt,
            maximumLivenessAttempt: tenantConfig.maximumLivenessAttempt,
            idScanAttempts: faceTecMatchIDScanResponse?.totalAttempts ?? 0,
            maximumIdMatchFrontAttempt: tenantConfig.maximumIDMatchFrontAttempt,
            maximumIdMatchBackAttempt: tenantConfig.maximumIDMatchBackAttempt,
          }),
      );
      Sentry.flush().then(() => {
        window.location.href = redirectUri;
      });
    }
  }, [livenessFailedAttempt, faceTecMatchIDScanResponse]);

  /**
   * Complete or retry id match after we get response from both REST & SSE server
   */
  useEffect(() => {
    if (faceTecMatchIDScanResponse !== null) {
      // Kill Facetec Session if Rest tell us to do so. only valid templates are allowed.
      if (faceTecMatchIDScanResponse.forceEndFacetecSession) {
        // Don't bother proceeding - just bail!
        faceTecProcessor?.idCheckResultCallbackCancel();
        Sentry.flush().then(() => {
          window.location.href = redirectUri;
        });
        return;
      }

      // If we are good...
      if (faceTecMatchIDScanResponse.idStatus === 'ACCEPTED') {
        // Pass the session through to facetec.
        faceTecProcessor?.idCheckResultCallbackProceedToNextStep(faceTecMatchIDScanResponse.resultBlob, faceTecMatchIDScanResponse.forceEndFacetecSession);

        // Else if the ID is not one we support...
      } else if (faceTecMatchIDScanResponse.idStatus === 'NOT_AUSTRALIAN_PHOTO_ID' || faceTecMatchIDScanResponse.idStatus === 'ID_INVALID') {
        // Bail and forward the user onto our error handler.
        dispatch(setIdStatusError(faceTecMatchIDScanResponse.idStatus));
        faceTecProcessor?.idCheckResultCallbackCancel();
        history.push(`error${history.location.search}`);
      }

      // If we failed this scan and we've had at least 1 attempt...
      if (!faceTecMatchIDScanResponse.passed && faceTecMatchIDScanResponse.totalSideAttempts >= 1) {
        // Set the match level to 1.
        dispatch(setMinimumIDMatchLevel(1));
      }
    }
  }, [faceTecMatchIDScanResponse]);

  /**
   * Enable button when FaceTec SDK is ready and we have a valid token
   */
  useEffect(() => {
    if (preInit) {
      return;
    }

    if (faceTecSessionToken.length > 0 && faceTecReadyState === FaceTecReadyState.Ready) {
      // Note: must not be a direct assign! This will cause state bugs and never let a session succeed.
      const faceTecCompletelyDoneCallback = () => undefined;
      const livenessCheckAndIDScanProcessor = new LivenessCheckAndIDScanProcessor(faceTecCompletelyDoneCallback);
      // eslint-disable-next-line no-new
      new FaceTecSDK.FaceTecSession(livenessCheckAndIDScanProcessor, faceTecSessionToken);
      setFaceTecProcessor(livenessCheckAndIDScanProcessor);
    }
  }, [faceTecSessionToken, faceTecReadyState, preInit]);

  useEffect(() => {
    if (preInit) {
      return;
    }

    if (faceTecSessionToken.length > 0 && faceTecReadyState === FaceTecReadyState.Fail) {
      Sentry.captureMessage(
        'FaceTec failed to initialize',
        (scope) => scope
          .setTag('status', FaceTecSDK.getStatus())
          .setContext('FaceTec SDK Status', {
            status: FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus()),
          })
          .setLevel('fatal')
          .setTransactionName('Facetec SDK Initialization'),
      );
      if (FaceTecSDK.getStatus() === 7) {
        setTimeout(() => {
          Sentry.flush().then(() => {
            window.location.href = redirectUri;
          });
        }, 5000);
      }
    }
  }, [faceTecSessionToken, faceTecReadyState, preInit]);

  let content = <></>;

  if (faceTecReadyState === FaceTecReadyState.Loading || isLoadingFaceTecSessionToken) {
    content = (
      <Box display="flex" flexDirection="column">
        <LoadingView loadingMessages={['Loading Liveness', 'Connecting to secure server', 'Preparing your data']} />
      </Box>
    );
  } else if (faceTecReadyState === FaceTecReadyState.Fail) {
    // DeviceLockedOut TODO :check the enum number to the valid string to be clear
    if (FaceTecSDK.getStatus() === 7) {
      content = (
        <Box display="flex" flexDirection="column">

          <Typography variant="body1">Sorry, please try again</Typography>

          <Typography variant="subtitle1" component="span">

            Unfortunately we were not able to prove liveness…
          </Typography>
        </Box>
      );
    } else {
      content = (
        <Box display="flex" flexDirection="column">
          <Typography variant="body1">Liveness system failed to initialize</Typography>
          <Typography variant="subtitle1" component="span">
            Error:
            {' '}
            {FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus())}
          </Typography>
        </Box>
      );
    }
  } else if (isFacetecSessionTokenEventTicketError) {
    content = (
      <ErrorView message="An error occurred." />
    );
  } else if (faceTecReadyState === FaceTecReadyState.Ready) {
    content = (
      <>
      </>
    );
  }
  return (
    <PageLayout hvCentered>
      {content}
    </PageLayout>
  );
}
