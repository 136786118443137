import { Event, EventHint } from '@sentry/react';

/**
 * Abbreviate the JSON data where strings may be too long
 *
 * This is a bit of a hack, but works flawlessly and recursively,
 * and means we don't need to roll our own.
 *
 * @param data the data to abbreviate
 */
function abbreviateJSONObject<T>(data: T): T {
  try {
    const obj = JSON.stringify(data, (key, value) => {
      if (typeof value === 'string' && value.length > 256) {
        return `[${value.length} chars]`;
      }
      return value;
    });

    return JSON.parse(obj);
  } catch (e) {
    return data;
  }
}

/**
 * beforeSend event for Sentry to trim out large blobs of data from context.
 *
 * This is mainly to fix the issue of large objects within our Redux state,
 * but can later be extended to support more features if required.
 * @param event
 * @param hint
 */
export function beforeSend(event: Event, hint?: EventHint): Event {
  if (event == null) {
    return event;
  }

  // SAFETY: The event type has been checked to only contain JSON-safe types.
  return abbreviateJSONObject(event);
}
