import { sendAnalyticsRaw } from './util';

/**
 * Send a log of page navigation to the analytics system
 *
 * @param page The page we navigated to
 */
export function logPageNavigation(page: string) {
  return sendAnalyticsRaw('reachedPage', { page });
}

export * from './facetec';
