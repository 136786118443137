import * as tenantConstants from '../../constants';
import { TenantConfig } from '../../objects/tenantConfig';
import StoreRegistry from '../../stores/storeRegistry';

export const types = {
  SET_CONFIG: 'SET_CONFIG',
};

function setConfig(config: TenantConfig) {
  return {
    type: types.SET_CONFIG,
    config,
  };
}

export function loadConfig(tenant: string) {
  const { store } = StoreRegistry;
  let loadedTenantConfig;
  if (tenant === 'nowfinance') {
    loadedTenantConfig = tenantConstants.NowFinanceConfig.config;
  } else if (tenant === 'peppermoney') {
    loadedTenantConfig = tenantConstants.PepperMoneyConfig.config;
  } else if (tenant === 'bryk') {
    loadedTenantConfig = tenantConstants.BrykConfig.config;
  } else {
    loadedTenantConfig = {
      tenantName: 'Bryk',
    };
  }
  store.dispatch(setConfig(loadedTenantConfig));
}
