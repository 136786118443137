import { ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logPageNavigation } from '../../services/AnalyticsService/actions';

/**
 * Add analytics to navigation
 */
export default function RouterLogger(props: { children?: ReactNode }): JSX.Element {
  const { children } = props;

  // Actually inject logging
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    // De-duplicated page logging
    let lastPage;
    const logPage = (page: string) => {
      if (page !== lastPage) {
        lastPage = page;
        dispatch(logPageNavigation(page));
      }
    };

    logPage(history.location.pathname);
    const unlisten = history.listen(((location) => logPage(location.pathname)));

    return () => {
      unlisten();
    };
  }, []);

  return children as JSX.Element;
}
