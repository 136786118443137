export enum GeoLocationStage {
  NOT_STARTED = 'NOT_STARTED',
  FETCHING_LOCATION = 'FETCHING_LOCATION',
  FETCH_LOCATION_FAILED = 'FETCH_LOCATION_FAILED',
  SUBMITTING_LOCATION = 'SUBMITTING_LOCATION',
  SUBMIT_LOCATION_FAILED = 'SUBMIT_LOCATION_FAILED',
  FINISHED_LOCATION = 'FINISHED_LOCATION',
}

type GeoLocationState = {
  geoLocationStage: GeoLocationStage,
  isLocationValid: boolean,
}

export default GeoLocationState;
