import OCRState from '../../objects/ocrState';
import { types } from './actions';

const initialState: OCRState = {
  isLoadingOCRData: false,
  ocrData: null,
  idImage: '',
  isOCRDataError: false,
  ocrFailedAttempt: 0,

  isLoadingModifyOCREventTicket: false,
  modifyOCREventTicketSuccessful: false,
  modifyOCREventTicket: '',
  isModifyOCREventTicketError: false,
};

export default function ocrReducer(state: OCRState = initialState, action): OCRState {
  switch (action.type) {
    case types.REQUEST_OCR_DATA:
      return {
        ...state, isLoadingOCRData: true, ocrData: null, idImage: '', isOCRDataError: false,
      };
    case types.RECEIVE_OCR_DATA:
      return {
        ...state, isLoadingOCRData: false, ocrData: action.ocrResults, idImage: action.idImage, isOCRDataError: false,
      };
    case types.RECEIVE_OCR_DATA_ERROR:
      return {
        ...state, isLoadingOCRData: false, ocrData: null, idImage: '', isOCRDataError: true,
      };
    case types.INCREASE_OCR_FAILED_ATTEMPT:
      return {
        ...state, ocrFailedAttempt: state.ocrFailedAttempt + 1,
      };
    case types.REQUEST_MODIFY_OCR_EVENT_TICKET:
      return {
        ...state, isLoadingModifyOCREventTicket: true, modifyOCREventTicket: '', modifyOCREventTicketSuccessful: false, isModifyOCREventTicketError: false,
      };
    case types.RECEIVE_MODIFY_OCR_EVENT_TICKET:
      return {
        ...state,
        isLoadingModifyOCREventTicket: false,
        modifyOCREventTicket: action.eventTicket,
        modifyOCREventTicketSuccessful: true,
        isModifyOCREventTicketError: false,
      };
    case types.RECEIVE_MODIFY_OCR_EVENT_TICKET_ERROR:
      return {
        ...state, isLoadingModifyOCREventTicket: false, modifyOCREventTicket: '', modifyOCREventTicketSuccessful: false, isModifyOCREventTicketError: true,
      };
    default:
      return state;
  }
}
