import CheckLivenessParameters from '../../objects/checkLivenessParameters';
import EventTicketResponse from '../../objects/eventTicketResponse';
import FaceTecEnrolmentParameters from '../../objects/faceTecEnrolmentParameters';
import IDScanParameters from '../../objects/idScanParameters';
import IDScanResponse from '../../objects/idScanResponse';
import { StoreRegistry } from '../../stores';

export const types = {
  REQUEST_FACETEC_SESSION_TOKEN: 'REQUEST_FACETEC_SESSION_TOKEN',
  RECEIVE_FACETEC_SESSION_TOKEN: 'RECEIVE_FACETEC_SESSION_TOKEN',
  RECEIVE_FACETEC_SESSION_TOKEN_TICKET: 'RECEIVE_FACETEC_SESSION_TOKEN_TICKET',
  RECEIVE_FACETEC_SESSION_TOKEN_ERROR: 'RECEIVE_FACETEC_SESSION_TOKEN_ERROR',

  REQUEST_FACETEC_ENROLMENT_EVENT_TICKET: 'REQUEST_FACETEC_ENROLMENT_EVENT_TICKET',
  RECEIVE_FACETEC_ENROLMENT: 'RECEIVE_FACETEC_ENROLMENT',
  RECEIVE_FACETEC_ENROLMENT_EVENT_TICKET: 'RECEIVE_FACETEC_ENROLMENT_EVENT_TICKET',
  RECEIVE_FACETEC_ENROLMENT_EVENT_TICKET_ERROR: 'RECEIVE_FACETEC_ENROLMENT_EVENT_TICKET_ERROR',

  REQUEST_FACETEC_MATCH_ID_SCAN_EVENT_TICKET: 'REQUEST_FACETEC_MATCH_ID_SCAN_EVENT_TICKET',
  RECEIVE_FACETEC_MATCH_ID_SCAN: 'RECEIVE_FACETEC_MATCH_ID_SCAN',
  RECEIVE_FACETEC_MATCH_ID_SCAN_EVENT_TICKET: 'RECEIVE_FACETEC_MATCH_ID_SCAN_EVENT_TICKET',
  RECEIVE_FACETEC_MATCH_ID_SCAN_EVENT_TICKET_ERROR: 'RECEIVE_FACETEC_MATCH_ID_SCAN_EVENT_TICKET_ERROR',

  SET_FACETEC_SESSION_COMPLETELY_DONE: 'SET_FACETEC_SESSION_COMPLETELY_DONE',
  RESET_FACETEC_SESSION_TO_START: 'RESET_FACETEC_SESSION_TO_START',

  INCREASE_LIVENESS_FAILED_ATTEMPT: 'INCREASE_LIVENESS_FAILED_ATTEMPT',
  SET_MINIMUM_ID_MATCH_LEVEL: 'SET_MINIMUM_ID_MATCH_LEVEL',
  SET_ID_STATUS_ERROR: 'SET_ID_STATUS_ERROR',
  RESET_FACETEC_MATCH_ID_SCAN_RESPONSE: 'RESET_FACETEC_MATCH_ID_SCAN_RESPONSE',
};

function requestFaceTecSessionToken() {
  return {
    type: types.REQUEST_FACETEC_SESSION_TOKEN,
  };
}

export function receiveFaceTecSessionTokenTicket(eventTicket: string) {
  return {
    type: types.RECEIVE_FACETEC_SESSION_TOKEN_TICKET,
    eventTicket,
  };
}

export function receiveFaceTecSessionToken(faceTecSessionToken: string) {
  return {
    type: types.RECEIVE_FACETEC_SESSION_TOKEN,
    faceTecSessionToken,
  };
}

export function receiveFaceTecSessionTokenError() {
  return {
    type: types.RECEIVE_FACETEC_SESSION_TOKEN_ERROR,
  };
}

export function fetchFaceTecSessionToken() {
  const endpoint = `${process.env.REACT_APP_REST_BASE_API_URL}/command`;
  const { authToken } = StoreRegistry.store.getState().auth;
  return (dispatch) => {
    dispatch(requestFaceTecSessionToken());
    return fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        command: 'facetecToken',
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json: EventTicketResponse) => {
            dispatch(receiveFaceTecSessionTokenTicket(json.eventTicket));
          });
        }
      })
      .catch(() => {
        dispatch(receiveFaceTecSessionTokenError());
      });
  };
}

function requestFaceTecEnrolmentEventTicket() {
  return {
    type: types.REQUEST_FACETEC_ENROLMENT_EVENT_TICKET,
  };
}

export function receiveFaceTecEnrolment(faceMapId: string) {
  return {
    type: types.RECEIVE_FACETEC_ENROLMENT,
    faceMapId,
  };
}

function receiveFaceTecEnrolmentEventTicket(eventTicket: string) {
  return {
    type: types.RECEIVE_FACETEC_ENROLMENT_EVENT_TICKET,
    eventTicket,
  };
}

function receiveFaceTecEnrolmentEventTicketError() {
  return {
    type: types.RECEIVE_FACETEC_ENROLMENT_EVENT_TICKET_ERROR,
  };
}

export function fetchFaceTecEnrolmentEventTicket(faceTecEnrolmentParameters: FaceTecEnrolmentParameters) {
  const endpoint = `${process.env.REACT_APP_REST_BASE_API_URL}/command`;
  const { authToken } = StoreRegistry.store.getState().auth;
  const faceTecParams: CheckLivenessParameters = { ...faceTecEnrolmentParameters, command: 'facetecLiveness' };
  return (dispatch) => {
    dispatch(requestFaceTecEnrolmentEventTicket());
    return fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(faceTecParams),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json: EventTicketResponse) => {
            dispatch(receiveFaceTecEnrolmentEventTicket(json.eventTicket));
          });
        } else {
          dispatch(receiveFaceTecEnrolmentEventTicketError());
        }
      })
      .catch(() => {
        dispatch(receiveFaceTecEnrolmentEventTicketError());
      });
  };
}

function requestFaceTecMatchIDScanEventTicket() {
  return {
    type: types.REQUEST_FACETEC_MATCH_ID_SCAN_EVENT_TICKET,
  };
}

export function receiveFaceTecMatchIDScan() {
  return {
    type: types.RECEIVE_FACETEC_MATCH_ID_SCAN,
  };
}

function receiveFaceTecMatchIDScanEventTicket(idScanResponse: IDScanResponse) {
  return {
    type: types.RECEIVE_FACETEC_MATCH_ID_SCAN_EVENT_TICKET,
    idScanResponse,
  };
}

function receiveFaceTecMatchIDScanEventTicketError() {
  return {
    type: types.RECEIVE_FACETEC_MATCH_ID_SCAN_EVENT_TICKET_ERROR,
  };
}

export function fetchFaceTecMatchIDScanEventTicket (idScan: string, idImage: string, facemapId: string, side: string, minLevel: number) {
  const endpoint = `${process.env.REACT_APP_REST_BASE_API_URL}/command`;
  const { authToken } = StoreRegistry.store.getState().auth;

  const idScanParameters: IDScanParameters = {
    idScan,
    idImage,
    facemapId,
    command: 'facetecId',
    forceDVS: false,
    side,
    minLevel,
  };

  return (dispatch) => {
    dispatch(requestFaceTecMatchIDScanEventTicket());
    return fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(idScanParameters),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json: IDScanResponse) => {
            dispatch(receiveFaceTecMatchIDScanEventTicket(json));
            dispatch(receiveFaceTecMatchIDScan());
          });
        } else {
          dispatch(receiveFaceTecMatchIDScanEventTicketError());
        }
      })
      .catch(() => {
        dispatch(receiveFaceTecMatchIDScanEventTicketError());
      });
  };
}

export function setFaceTecSessionCompletelyDone() {
  return {
    type: types.SET_FACETEC_SESSION_COMPLETELY_DONE,
  };
}

export function resetFaceTecSessionToStart() {
  return {
    type: types.RESET_FACETEC_SESSION_TO_START,
  };
}

export function increaseLivenessFailedAttempt() {
  return {
    type: types.INCREASE_LIVENESS_FAILED_ATTEMPT,
  };
}

export function setMinimumIDMatchLevel(minIDMatchLevel: number) {
  return {
    type: types.SET_MINIMUM_ID_MATCH_LEVEL,
    minIDMatchLevel,
  };
}

export function setIdStatusError(idStatusError: string) {
  return {
    type: types.SET_ID_STATUS_ERROR,
    idStatusError,
  };
}

export function resetfaceTecMatchIDScanResponse() {
  return {
    type: types.RESET_FACETEC_MATCH_ID_SCAN_RESPONSE,
  };
}
