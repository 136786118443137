/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import {
  Box, Grid, TextField, Typography, useTheme, NativeSelect,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BounceLoader } from 'react-spinners';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { format as formatDate } from 'date-fns';
import ModifyOcrResponse from '../../objects/modifyOcrResponse';
import ReducerRootState from '../../objects/reducerRootState';
import { fetchModifyOCREventTicket, fetchOCRData, increaseOCRFailedAttempt } from '../../services/OCRService';
import ErrorView from '../ErrorView';
import LoadingView from '../LoadingView';
import PageLayout from '../PageLayout';
import { selectFullRedirectUri } from '../../services/AuthService';
import { isWebSocketError } from '../../objects/webSocketError';
import { logDVSPageSubmitted, logDVSPageError } from '../../services/AnalyticsService/actions/dvs';

const StyledButton = styled.button`
  color: ${(props) => props.color};
  border: 2px solid ${(props) => props.color};
  border-radius: 24px;
  font-weight: 600;
  height: 48px;
  background-color: #fff;
  cursor: pointer;
  width: 210px;

  &:hover {
    color: #fff;
    background-color: ${(props) => (props.loading ? '#fff0' : props.color)};
  }
`;

enum IDType {
  DriversLicense,
  Passport
}

export default function OCRPage() {
  const {
    isLoadingOCRData, ocrData, isOCRDataError, idImage,
  } = useSelector((state: ReducerRootState) => state.ocr);
  const sseEvents = useSelector((state: ReducerRootState) => state.websocket.events);
  const modifyOCREventTicket = useSelector((state: ReducerRootState) => state.ocr.modifyOCREventTicket);
  const redirectUri = useSelector(selectFullRedirectUri);
  const ocrFailedAttempt = useSelector((state: ReducerRootState) => state.ocr.ocrFailedAttempt);
  const tenantConfig = useSelector((state: ReducerRootState) => state.config.config);
  const faceTecSessionCompletelyDone = useSelector((state: ReducerRootState) => state.faceTec.faceTecSessionComplelyDone);
  const dispatch = useDispatch();
  const theme = useTheme();
  const statesData = [
    { value: 'VIC', name: 'VIC' },
    { value: 'NSW', name: 'NSW' },
    { value: 'QLD', name: 'QLD' },
    { value: 'SA', name: 'SA' },
    { value: 'TAS', name: 'TAS' },
    { value: 'WA', name: 'WA' },
    { value: 'ACT', name: 'ACT' },
    { value: 'NT', name: 'NT' },
  ];
  const cardNumberStates = `${process.env.REACT_APP_CARD_NUMBER_STATES}`.split(',');
  const idType = useMemo(() => {
    switch (ocrData?.idList[0].idType) {
      case 'PASSPORT': return IDType.Passport;
      case 'DRIVERS_LICENCE': return IDType.DriversLicense;
      default: {
        console.warn('Invalid/missing ID type, falling back');
        return IDType.DriversLicense;
      }
    }
  }, [ocrData?.idList[0].idType]);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [idState, setIdState] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [address, setAddress] = useState('');
  const [postCode, setPostCode] = useState('');
  const [suburb, setSuburb] = useState('');
  const [addressState, setAddressState] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [displayCardNumber, setDisplayCardNumber] = useState(false);
  const [localIdImage, setLocalIdImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [displayEmptyFieldError, setDisplayEmptyFieldError] = useState(false);

  useEffect(() => {
    if (!faceTecSessionCompletelyDone) {
      return;
    }
    dispatch(fetchOCRData());
  }, []);

  useEffect(() => {
    if (ocrData !== null) {
      setLocalIdImage(idImage);
      setFirstName(ocrData.name.first);
      setLastName(ocrData.name.last);
      setMiddleName(ocrData.name.middle);
      setDateOfBirth(new Date(ocrData.dateOfBirth));
      if (idType === IDType.DriversLicense) {
        setIdState(ocrData.idList[0].registrationPlace);

        const isCardNumberVisible = cardNumberStates.includes(ocrData.idList[0].registrationPlace);
        if (isCardNumberVisible) {
          setCardNumber(ocrData.idList[0].cardNumber?.toString());
        }
        setDisplayCardNumber(isCardNumberVisible);
      }
      setIdNumber(ocrData.idList[0].idNumber.toString());
      setAddress(ocrData.addressList[0].firstLine);
      setPostCode(ocrData.addressList[0].postcode);
      setSuburb(ocrData.addressList[0].suburb);
      setAddressState(ocrData.addressList[0].state);
      setCountryCode(ocrData.addressList[0].countryCode);
    }
  }, [ocrData]);

  useEffect(() => {
    const latestSSEEvent = sseEvents[sseEvents.length - 1];
    if (modifyOCREventTicket.length > 0 && latestSSEEvent !== undefined && modifyOCREventTicket === latestSSEEvent.eventTicket) {
      // Internal server error, handle this correctly
      if (isWebSocketError(latestSSEEvent.result)) {
        const { error } = latestSSEEvent.result;

        Sentry.captureException(new Error('DVS SSE error'), (scope) => scope.setContext('error', { error }));
        console.error('DVS SSE error', error);
        setDisplayError(true);
        setLoading(false);
        dispatch(logDVSPageError(`API error: ${error}`));
        return;
      }

      const modifyOcrResponse = (latestSSEEvent.result as ModifyOcrResponse);
      if (modifyOcrResponse.modifySuccess && modifyOcrResponse.dvsResult.verification && modifyOcrResponse.dvsResult.verification.verificationSuccess) {
        Sentry.flush().then(() => {
          window.location.href = redirectUri;
        });
      } else {
        dispatch(increaseOCRFailedAttempt());
        setDisplayError(true);
        setLoading(false);
        dispatch(logDVSPageError('User error: DVS failed to verify user'));
      }
    }
  }, [modifyOCREventTicket, sseEvents]);

  useEffect(() => {
    if (ocrFailedAttempt < tenantConfig.maximumDVSAttempt) return;
    Sentry.captureException(
      new Error('Exceeded max DVS attempts'),
      (scope) => scope
        .setContext('attempts', {
          ocrFailedAttempt,
          maximumDVSAttempt: tenantConfig.maximumDVSAttempt,
        }),
    );
    Sentry.flush().then(() => {
      window.location.href = redirectUri;
    });
  }, [ocrFailedAttempt]);

  const saveDetails = () => {
    setDisplayError(false);
    const modifiedOCRData = ocrData;
    const originalDateOfBirth = formatDate(dateOfBirth, 'dd/MM/yyyy');
    if (modifiedOCRData !== null) {
      let allDataValid = firstName.length > 0
        && lastName.length > 0
        && idNumber.length > 0
        && address.length > 0
        && postCode.length > 0
        && suburb.length > 0
        && addressState.length > 0
        && countryCode.length > 0
        && (originalDateOfBirth?.length === 10 || originalDateOfBirth !== 'Invalid Date');

      if (idType === IDType.DriversLicense) {
        allDataValid &&= idState.length > 0;

        const isCardNumberVisible = cardNumberStates.includes(modifiedOCRData.idList[0].registrationPlace);
        if (isCardNumberVisible) {
          allDataValid &&= cardNumber.length > 0;
        }
      }

      dispatch(logDVSPageSubmitted(allDataValid));

      if (allDataValid) {
        setDisplayEmptyFieldError(false);
        setLoading(true);
        modifiedOCRData.name.first = firstName;
        modifiedOCRData.name.last = lastName;
        modifiedOCRData.name.middle = middleName;
        modifiedOCRData.dateOfBirth = originalDateOfBirth;
        modifiedOCRData.idList[0].idNumber = idNumber;
        if (idType === IDType.DriversLicense) {
          modifiedOCRData.idList[0].registrationPlace = idState;
          modifiedOCRData.idList[0].cardNumber = cardNumber;
        }
        modifiedOCRData.addressList[0].firstLine = address;
        modifiedOCRData.addressList[0].suburb = suburb;
        modifiedOCRData.addressList[0].postcode = postCode;
        modifiedOCRData.addressList[0].state = addressState;
        modifiedOCRData.addressList[0].countryCode = countryCode;
        Sentry.captureMessage('send modified OCR details to API', (scope) => scope.setExtra('OCR Details', {
          dateOfBirth: modifiedOCRData.dateOfBirth,
          firstName: modifiedOCRData.name.first,
          lastName: modifiedOCRData.name.last,
        }));
        Sentry.flush();
        dispatch(fetchModifyOCREventTicket(modifiedOCRData));
      } else {
        setDisplayEmptyFieldError(true);
      }
    }
  };

  const getDateIgnoreTimezone = (date) => (date && date.getUTCHours() !== 0
    ? ((theDate) => new Date(theDate.getTime() - theDate.getTimezoneOffset() * 60 * 1000))(
      new Date(date),
    )
    : date);

  const handleDateChange = (date) => {
    const theDate = getDateIgnoreTimezone(date);
    setDateOfBirth(theDate);
    console.log(date);
    console.log(theDate);
    // setDateOfBirth(date);
  };

  let content = <></>;
  if (!faceTecSessionCompletelyDone) {
    content = (
      <ErrorView message="An error occurred. Complete the session before editing the information" />
    );
  } else if (isLoadingOCRData) {
    content = <LoadingView />;
  } else if (isOCRDataError) {
    content = <ErrorView />;
  } else if (ocrData !== null) {
    content = (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="justify" flex={1}>
        <Box mb={2} mt={2}>
          <Typography variant="h4">
            Confirm details
          </Typography>
        </Box>
        <Box pl={2} pr={2}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <img
                alt="idImage"
                src={`data:image/jpeg;base64,${localIdImage}`}
                width="100%"
                height="100%"
              />
            </Grid>
            <Grid item xs={12}>
              <form noValidate autoComplete="off">
                <Box mb={3}>
                  <Box mb={2}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>Name</Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <>
                      <Grid item xs={12} md={6}>
                        <TextField fullWidth disabled label="First Name" variant="outlined" value={firstName} onChange={(event) => { setFirstName(event.target.value); }} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField fullWidth disabled label="Middle Name" variant="outlined" value={middleName} onChange={(event) => { setMiddleName(event.target.value); }} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField fullWidth disabled label="Last Name" variant="outlined" value={lastName} onChange={(event) => { setLastName(event.target.value); }} />
                      </Grid>
                    </>
                  </Grid>
                </Box>

                <Box mb={3}>
                  <Box mb={2}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>Date of Birth</Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <KeyboardDatePicker
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        maxDate={new Date()}
                        maxDateMessage="Date of Birth can not be in the future"
                        disableFuture
                        id="date-picker-inline"
                        label="Date of Birth"
                        value={dateOfBirth}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date of birth',
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box mb={4}>
                  <Box mb={2}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>Identification</Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField fullWidth label="Number" variant="outlined" value={idNumber} onChange={(event) => { setIdNumber(event.target.value); }} />
                    </Grid>
                    {idType === IDType.Passport
                      ? <></>
                      : (
                        <Grid item xs={6}>
                          {/* <TextField fullWidth label="State" variant="outlined" value={idState} onChange={(event) => { setIdState(event.target.value); }} /> */}
                          <NativeSelect
                            variant="outlined"
                            fullWidth
                            disabled
                            value={idState}
                          >
                            {statesData.map((e) => <option key={e.name} value={e.value}>{e.name}</option>)}
                          </NativeSelect>
                        </Grid>
                      )}
                    {displayCardNumber
                      ? (
                        <Grid item xs={6}>
                          <TextField fullWidth label="Card Number" variant="outlined" value={cardNumber} onChange={(event) => { setCardNumber(event.target.value); }} />
                        </Grid>
                      )
                      : <></>}
                  </Grid>
                </Box>

                <Box mb={4}>
                  <Box mb={2}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>Address</Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField fullWidth label="Address" variant="outlined" value={address} onChange={(event) => { setAddress(event.target.value); }} />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField fullWidth label="Suburb" variant="outlined" value={suburb} onChange={(event) => { setSuburb(event.target.value); }} />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField fullWidth label="Postcode" variant="outlined" value={postCode} onChange={(event) => { setPostCode(event.target.value); }} />
                    </Grid>
                    <Grid item xs={6}>
                      {/* <TextField fullWidth label="State" variant="outlined" value={addressState} onChange={(event) => { setAddressState(event.target.value); }} /> */}
                      <NativeSelect
                        variant="outlined"
                        fullWidth
                        value={addressState}
                        onChange={(event) => { setAddressState(event.target.value as string); }}
                      >
                        {statesData.map((e) => <option key={e.name} value={e.value}>{e.name}</option>)}
                      </NativeSelect>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField fullWidth label="Country Code" variant="outlined" value={countryCode} onChange={(event) => { setCountryCode(event.target.value); }} />
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </Grid>

          </Grid>

          <Grid container spacing={2}>
            {displayError && (
            <Grid item xs={12}>
              <Typography variant="body1" style={{ color: 'red' }}>
                <b>
                  Identity check failed. Please provide correct information and try again.
                </b>
              </Typography>
            </Grid>
            )}
            {displayEmptyFieldError && (
            <Grid item xs={12}>
              <Typography variant="body1" style={{ color: 'red' }}>
                <b>
                  You cannot have an empty field.
                </b>
              </Typography>
            </Grid>
            )}
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                <StyledButton
                  onClick={() => {
                    saveDetails();
                  }}
                  type="submit"
                  fullWidth
                  color={theme.palette.primary.main}
                  style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
                  disabled={loading}
                >
                  {loading ? <BounceLoader color={theme.palette.primary.main} size={20} /> : <span>Continue</span>}
                </StyledButton>
              </Box>
            </Grid>
          </Grid>

        </Box>
      </Box>
    );
  }

  return (
    <PageLayout>
      <Box display="flex" flexDirection="column" alignItems="center">
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'calc(100vh - 156px)',
            display: 'flex',
            width: '100%',
          }}
        >
          {content}
        </div>
      </Box>
    </PageLayout>
  );
}
