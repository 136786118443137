import WebSocketState from '../../objects/webSocketState';
import { types } from './actions';

const initialState: WebSocketState = {
  websocket: null,
  events: [],

  connectionAttempts: 0,
};

export default function websocketReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_WEBSOCKET:
      return {
        ...state, websocket: action.websocket,
      };
    case types.RECEIVE_WEBSOCKET_EVENT:
      return {
        ...state, events: [...state.events, action.websocketEvent],
      };
    case types.INCREMENT_WEBSOCKET_RETRIES:
      return {
        ...state, connectionAttempts: state.connectionAttempts + 1,
      };
    case types.RESET_WEBSOCKET_RETRIES:
      return {
        ...state, connectionAttempts: 0,
      };
    default:
      return state;
  }
}
