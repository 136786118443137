import EnvironmentState from '../../objects/environmentState';
import { types } from './actions';

const initialState: EnvironmentState = {
  nodeEnv: '',
  environment: '',
  tenant: '',
  redirectUri: '',
  clientID: '',
  brykSessionToken: '',
  completedInitialSetup: false,
};

export default function environmentReducer(state: EnvironmentState = initialState, action) {
  switch (action.type) {
    case types.INITIAL_SETUP:
      return {
        ...state,
        nodeEnv: action.nodeEnv,
        environment: action.environment,
        tenant: action.tenant,
        redirectUri: action.redirectUri,
        clientID: action.clientID,
        brykSessionToken: action.brykSessionToken,
      };
    case types.SET_NODE_ENV:
      return { ...state, nodeEnv: action.nodeEnv };
    case types.SET_ENVIRONMENT:
      return { ...state, environment: action.environment };
    case types.SET_TENANT:
      return { ...state, tenant: action.tenant };
    case types.COMPLETE_INITIAL_SETUP:
      return {
        ...state, completedInitialSetup: true,
      };
    default:
      return state;
  }
}
