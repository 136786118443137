import React, { useCallback, useState } from 'react';
import { Button, Snackbar } from '@material-ui/core';

function oldschoolCopyText(textToCopy: string) {
  const input = document.createElement('input');
  input.value = textToCopy;

  const oldActive = document.activeElement as Element & { focus?: () => void };
  const refocusOld = () => {
    if (typeof oldActive?.focus === 'function') {
      oldActive.focus();
    }
  };

  input.focus();
  input.select();
  input.setSelectionRange(0, 99999);

  document.execCommand('copy');
  refocusOld();
}

function copyText(textToCopy: string) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(textToCopy)
      .catch(() => oldschoolCopyText(textToCopy));
  } else {
    oldschoolCopyText(textToCopy);
  }
}

export default function CopyInput(props: { value: string, fixedHackyButtonGroupBullshit?: boolean }) {
  const { value, fixedHackyButtonGroupBullshit } = props;
  const [open, setOpen] = useState(false);
  const onBtnClick = useCallback(() => { copyText(value); setOpen(true); }, [setOpen, value]);

  const snackBar = (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      onClose={() => setOpen(false)}
      message="Copied Link to clipboard"
    />
  );

  const styleOverride = fixedHackyButtonGroupBullshit ? { borderTopLeftRadius: 0, borderTopRightRadius: 0 } : {};

  return (
    <>
      <Button variant="outlined" color="primary" style={styleOverride} onClick={onBtnClick}>
        Copy Link
        {snackBar}
      </Button>
    </>
  );
}

CopyInput.defaultProps = {
  fixedHackyButtonGroupBullshit: false,
};
