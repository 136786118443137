import { OCRResults } from '../../objects/ocrResults';
import EventTicketResponse from '../../objects/eventTicketResponse';
import OCRResponse from '../../objects/ocrResponse';
import { StoreRegistry } from '../../stores';
import { logOCRDataLoaded, logOCRDataLoadError } from '../AnalyticsService/actions/dvs';

export const types = {
  REQUEST_OCR_DATA: 'REQUEST_OCR_DATA',
  RECEIVE_OCR_DATA: 'RECEIVE_OCR_DATA',
  RECEIVE_OCR_DATA_ERROR: 'RECEIVE_OCR_DATA_ERROR',
  INCREASE_OCR_FAILED_ATTEMPT: 'INCREASE_OCR_FAILED_ATTEMPT',

  REQUEST_MODIFY_OCR_EVENT_TICKET: 'REQUEST_MODIFY_OCR_EVENT_TICKET',
  RECEIVE_MODIFY_OCR_EVENT_TICKET: 'RECEIVE_MODIFY_OCR_EVENT_TICKET',
  RECEIVE_MODIFY_OCR_EVENT_TICKET_ERROR: 'RECEIVE_MODIFY_OCR_EVENT_TICKET_ERROR',
};

function requestOCRData() {
  return {
    type: types.REQUEST_OCR_DATA,
  };
}

function receiveOCRData(ocrResults: OCRResults, idImage: string) {
  return {
    type: types.RECEIVE_OCR_DATA,
    ocrResults,
    idImage,
  };
}

function receiveOCRDataError() {
  return {
    type: types.RECEIVE_OCR_DATA_ERROR,
  };
}

export function fetchOCRData() {
  const endpoint = `${process.env.REACT_APP_REST_BASE_API_URL}/command`;
  const { authToken } = StoreRegistry.store.getState().auth;
  return (dispatch) => {
    dispatch(requestOCRData());
    return fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // eslint-disable-next-line max-len
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ command: 'retrieveOCRResults' }),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json: OCRResponse) => {
            dispatch(logOCRDataLoaded());
            dispatch(receiveOCRData(json.userInfo, json.idImage));
          });
        } else {
          response.text()
            .then((body) => dispatch(logOCRDataLoadError(`Body: ${body}`)))
            .catch((err) => dispatch(logOCRDataLoadError(`Body error: ${JSON.stringify(err)}`)));
          dispatch(receiveOCRDataError());
        }
      })
      .catch((err) => {
        dispatch(logOCRDataLoadError(`Submit error: ${JSON.stringify(err)}`));
        dispatch(receiveOCRDataError());
      });
  };
}

export function increaseOCRFailedAttempt() {
  return {
    type: types.INCREASE_OCR_FAILED_ATTEMPT,
  };
}

function requestModifyOCREventTicket() {
  return {
    type: types.REQUEST_MODIFY_OCR_EVENT_TICKET,
  };
}

function receiveModifyOCREventTicket(eventTicket: string) {
  return {
    type: types.RECEIVE_MODIFY_OCR_EVENT_TICKET,
    eventTicket,
  };
}

function receiveModifyOCREventTicketError() {
  return {
    type: types.RECEIVE_MODIFY_OCR_EVENT_TICKET_ERROR,
  };
}

export function fetchModifyOCREventTicket(modifiedOCRData: OCRResults) {
  const endpoint = `${process.env.REACT_APP_REST_BASE_API_URL}/command`;
  const { authToken } = StoreRegistry.store.getState().auth;
  return (dispatch) => {
    dispatch(requestModifyOCREventTicket());
    return fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ command: 'modifyOCRResults', ocrResults: modifiedOCRData }),
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json: EventTicketResponse) => {
            dispatch(receiveModifyOCREventTicket(json.eventTicket));
          });
        } else {
          dispatch(receiveModifyOCREventTicketError());
        }
      })
      .catch(() => {
        dispatch(receiveModifyOCREventTicketError());
      });
  };
}
