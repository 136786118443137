import { createTheme } from '@material-ui/core/styles';
import { StoreRegistry } from '../../stores';
import * as tenantThemes from '../../themes';

export const types = {
  SET_THEME: 'SET_THEME',
};

function setTheme(theme) {
  return {
    type: types.SET_THEME,
    theme,
  };
}

export function setupTheme(tenant: string) {
  const { store } = StoreRegistry;
  let tenantTheme;
  let tenantMuiTheme;
  if (tenant === 'nowfinance') {
    tenantTheme = tenantThemes.NowFinanceTheme.default;
    tenantMuiTheme = createTheme(tenantTheme);
  } else if (tenant === 'peppermoney') {
    tenantTheme = tenantThemes.PepperMoneyTheme.default;
    tenantMuiTheme = createTheme(tenantTheme);
  } else if (tenant === 'bryk') {
    tenantTheme = tenantThemes.BrykTheme.default;
    tenantMuiTheme = createTheme(tenantTheme);
  } else {
    tenantTheme = {};
    tenantMuiTheme = createTheme(tenantTheme);
  }
  store.dispatch(setTheme(tenantMuiTheme));
}
