import { sendAnalyticsRaw } from './util';

/**
 * Log the browser warning being displayed to the user
 */
export function logBrowserDisplayWarning() {
  return sendAnalyticsRaw('displayBrowserSupportWarning', {});
}

/**
 * Log the browser warning being dismissed by the user
 */
export function logBrowserDismissWarning() {
  return sendAnalyticsRaw('dismissBrowserSupportWarning', {});
}
