import GeoLocationState, { GeoLocationStage } from '../../objects/geoLocationState';
import { types } from './actions';

const initialState: GeoLocationState = {
  geoLocationStage: GeoLocationStage.NOT_STARTED,
  isLocationValid: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function locationReducer(state: GeoLocationState = initialState, action: { type: typeof types } & any): GeoLocationState {
  switch (action.type) {
    case types.START_GEO_LOCATION:
      return {
        ...state,
        geoLocationStage: GeoLocationStage.FETCHING_LOCATION,
      };
    case types.SEND_SET_GEO_LOCATION:
      return {
        ...state,
        geoLocationStage: GeoLocationStage.SUBMITTING_LOCATION,
      };
    case types.FAIL_GEO_LOCATION:
      return {
        ...state,
        geoLocationStage: GeoLocationStage.FETCH_LOCATION_FAILED,
      };
    case types.RECEIVE_GEO_LOCATION_RESPONSE:
      return {
        ...state,
        isLocationValid: action.success,
        geoLocationStage: action.success ? GeoLocationStage.FINISHED_LOCATION : GeoLocationStage.SUBMIT_LOCATION_FAILED,
      };

    default:
      return state;
  }
}
