export const getTenantForDevelopment = (urlParams: URLSearchParams): string => {
  const tenant = urlParams.get('tenant');
  return tenant || 'bryk';
};

// Just give us the bryk tenant, we don't want the others
export const getTenantForProduction = (): string => 'bryk';

/*
  const hostParts = window.location.host.split('.');
  const tenantRoot = hostParts.slice(-3).join('.');

  switch (tenantRoot) {
    case 'nowfinance.brykid.com': return 'nowfinance';
    case 'peppermoney.brykid.com': return 'peppermoney';
    case 'dev.brykid.com': return 'bryk';
    case 'demo.brykid.com': return 'bryk';
    default: return hostParts[1];
  }
 */
