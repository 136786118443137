import * as Sentry from '@sentry/react';
import WebSocketEvent from '../../objects/webSocketEvent';
import { StoreRegistry } from '../../stores';
import { receiveDVSResult } from '../DataVerificationService';
import { receiveFaceTecEnrolment, receiveFaceTecMatchIDScan } from '../FaceTecService';
import { selectFullRedirectUri } from '../AuthService';

export const types = {
  SET_WEBSOCKET: 'SET_WEBSOCKET',
  RECEIVE_WEBSOCKET_EVENT: 'RECEIVE_WEBSOCKET_EVENT',

  INCREMENT_WEBSOCKET_RETRIES: 'INCREMENT_WEBSOCKET_RETRIES',
  RESET_WEBSOCKET_RETRIES: 'RESET_WEBSOCKET_RETRIES',
};

function setWebSocket(websocket: WebSocket) {
  return {
    type: types.SET_WEBSOCKET,
    websocket,
  };
}

function receiveWebSocketEvent(websocketEvent: WebSocketEvent) {
  const { store } = StoreRegistry;
  if (websocketEvent.eventType === 'facetecLiveness' && (websocketEvent.result).success) {
    const { result } = websocketEvent;
    store.dispatch(receiveFaceTecEnrolment((result).facemapId));
  }
  // TODO: Change this to websocketEvent.result.success when we have better result from FaceTec
  if (websocketEvent.eventType === 'facetecId' && websocketEvent.result) {
    store.dispatch(receiveFaceTecMatchIDScan());
  }
  if (websocketEvent.eventType === 'modifyOCRResults') {
    store.dispatch(receiveDVSResult((websocketEvent.result)));
  }
  return {
    type: types.RECEIVE_WEBSOCKET_EVENT,
    websocketEvent,
  };
}

function incrementWebsocketRetries() {
  return {
    type: types.INCREMENT_WEBSOCKET_RETRIES,
  };
}

function resetWebsocketRetries() {
  return {
    type: types.RESET_WEBSOCKET_RETRIES,
  };
}

export function initiateWebSocket() {
  const { store } = StoreRegistry;
  const { authToken } = store.getState().auth;
  Sentry.addBreadcrumb({
    message: 'WebSocket connection attempt',
    level: 'debug',
    type: 'websocket',
  });
  const ws = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_BASE_API_URL}/ws?jwt=${authToken}`);
  let pingInterval: number | undefined;
  let pingCount = 0;
  let closedDueToPingExceeded = false;

  const pongHandler = () => {
    pingCount = 0;
  };

  ws.addEventListener('open', () => {
    Sentry.addBreadcrumb({
      message: 'WebSocket opened',
      level: 'debug',
      type: 'websocket',
    });

    // Ping/pong check
    // Note: We don't need to get a reply to this. The TCP ACK that happens behind the scenes acts as our pong.
    // All we care about for this is detecting something failed to send. If it does, the websocket error handler will fire.
    pingCount = 0;
    pingInterval = window.setInterval(() => {
      pingCount += 1;
      if (pingCount > 3) {
        Sentry.captureException(new Error('Pending ping count exceeded, killing websocket'));
        closedDueToPingExceeded = true;
        ws.close();
      } else {
        ws.send('{"type":"PING"}');
      }
    }, 1000);

    store.dispatch(resetWebsocketRetries());
    store.dispatch(setWebSocket(ws));
  });

  ws.addEventListener('error', (err) => {
    Sentry.addBreadcrumb({ message: 'WebSocket encountered an error', level: 'warning', type: 'websocket' });

    // eslint-disable-next-line no-console
    console.error('WebSocket failed:', err);

    Sentry.captureMessage('WebSocket encountered an error', (scope) => {
      let readyState;
      switch (ws.readyState) {
        case WebSocket.CLOSED: readyState = 'CLOSED'; break;
        case WebSocket.OPEN: readyState = 'OPEN'; break;
        case WebSocket.CONNECTING: readyState = 'CONNECTING'; break;
        default: readyState = `UNKNOWN STATE ${ws.readyState}`; break;
      }

      return scope
        .setTransactionName('WebSocket connection')
        .setContext('WebSocket readyState', {
          readyState,
        });
    });

    // ws.close();
  });

  ws.addEventListener('message', (msg) => {
    const response = JSON.parse(msg.data);

    if (response.type === 'PONG') {
      pongHandler();
    } else {
      store.dispatch(receiveWebSocketEvent(response));
    }
  });

  ws.addEventListener('close', (evt) => {
    // eslint-disable-next-line no-console
    console.log('close');
    Sentry.addBreadcrumb({
      message: 'WebSocket closed',
      level: 'debug',
      type: 'websocket',
    });

    if (pingInterval !== undefined) {
      window.clearInterval(pingInterval);
      pingInterval = undefined;
    }

    if (!evt.wasClean || closedDueToPingExceeded) {
      closedDueToPingExceeded = false;
      setTimeout(() => {
        // 10 attempts before we give up
        const storeState = store.getState();
        if (storeState.websocket.connectionAttempts >= 5) {
          const redirectUri = selectFullRedirectUri(storeState);

          Sentry.captureMessage('Websocket connection retry attempts exceeded', (scope) => scope
            .setLevel('fatal'));

          Sentry.flush().then(() => {
            window.location.href = redirectUri;
          });
        } else {
          store.dispatch(incrementWebsocketRetries());
          initiateWebSocket();
        }
      }, 1500);
    }

    if (evt.reason === 'UNAUTHORIZED') {
      const redirectUri = selectFullRedirectUri(store.getState());

      Sentry.captureMessage('Unauthorized websocket connection', (scope) => scope
        .setLevel('fatal'));

      Sentry.flush().then(() => {
        window.location.href = redirectUri;
      });
    }

    if (evt.reason === 'NOT_FOUND') {
      // If you get this, you fucked up.
      // eslint-disable-next-line no-console
      console.error('Websocket endpoint is misconfigured.');
    }
  });
}
