import {
  Box, Grid,
} from '@material-ui/core';
import { PropsWithChildren } from 'react';
import PageFooter from '../PageFooter';

import NowFinanceBackground from '../../assets/now_finance_background.svg';

function HVCentered(props: PropsWithChildren<unknown>) {
  const { children } = props;

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 156px)',
        display: 'flex',
      }}
      >
        {children}
      </div>
    </Box>
  );
}

function PageLayout(props: PropsWithChildren<{hvCentered?: boolean}>) {
  const { hvCentered, children } = props;

  const content = hvCentered ? <HVCentered>{children}</HVCentered> : children;

  return (
    <Box display="flex" flexDirection="column" minHeight="calc(100vh - 88px)">
      <Grid
        container
        style={{
          flex: 1,
          backgroundImage: `url(${NowFinanceBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Grid item xs={1} md={2} lg={2} xl={3} />
        <Grid item xs={10} md={8} lg={8} xl={6}>
          {content}
        </Grid>
        <Grid item xs={1} md={2} lg={2} xl={3} />
      </Grid>
      <PageFooter />
    </Box>
  );
}

PageLayout.defaultProps = {
  hvCentered: false,
};

export default PageLayout;
